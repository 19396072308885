<app-header></app-header>
<div class="center-item" *ngIf="!reservation">
  <ng-lottie
    [options]="options"
    width="200px"
    height="200px"
  ></ng-lottie>
</div>
<div class="container-fluid main-container" *ngIf="reservation">

  <h1 class="view-title">
    Reservation {{reservation.reservationCode}}
    <span class="glyphicon glyphicon-info-sign special-icon" tooltip="Reservation includes rooms for disabled"
      placement="bottom" *ngIf="hasDisabled"></span>
    <!-- <span class="glyphicon glyphicon-baby-formula special-icon" tooltip="Reservation includes rooms for children"
      placement="bottom" *ngIf="hasChildren"></span> -->
  </h1>
  <!-- <h2 class="margin-bottom-20">Hotel {{reservation.hotel.dropdownHotelName}}</h2> -->

  <div class="row" style="margin-bottom: 15px;">
    <div class="col-md-3">
      <span style="margin-right: 10px;" *ngIf="hotels.length > 1">{{showHotelText()}}{{utils.hotelDisplayByHotel(hotelConfig, reservation.hotel)}}</span>
      <span>
        <img [ngClass]="{hidden: reservation.state !== 'CONFIRMED'}" src="assets/images/confirm.svg" style="position: relative;top: -2px;margin-right: 5px;">
        <b [style]="{color: reservation.state === 'INITIAL' ? 'red' : '#424F68'}">{{utils.showReservationStatus(reservation.state)}}</b>
      </span>
    </div>
    <div class="col-md-9 text-right" *ngIf="!editAllowed && paymentAllowed && ( (isExternalReservation() && hotelConfig.MUIfeature.allowExternalReservationRefund) || !isExternalReservation() )">
      <button type="button" (click)="openRefundModal()" class="btn btn-green btn-big left-margin top-margin"><span
        class="glyphicon glyphicon-share-alt"></span> Refund</button>
    </div>
    <div class="col-md-9 text-right" *ngIf="editAllowed">
      <ng-container *ngIf="!isEditDisabled() && inputEdited || isAllowExternalReservationAddExtra() && inputEdited">
        <button type="button" (click)="saveReservationCheck()" [disabled]="disabledBtnWhileCallingAPI" class="btn btn-green btn-big left-margin top-margin"><span
            class="glyphicon glyphicon-save"></span> Save changes</button>
        <button type="button" (click)="revertReservation()" class="btn btn-green btn-big left-margin  top-margin"><span
            class="glyphicon glyphicon-step-backward"></span> Revert changes</button>
      </ng-container>
      <button type="button" *ngIf="isPendingConfirmation()" [disabled]="isLoadingConfirm" (click)="confirmReservation()"
        class="btn btn-green btn-big left-margin top-margin"><span class="glyphicon glyphicon-align-right"></span> Confirm</button>
      <button type="button" *ngIf="isBlocked()" (click)="openUnblockModal()"
        class="btn btn-green btn-big left-margin top-margin"><span class="glyphicon glyphicon-align-right"></span> Unblock</button>
      <button type="button" *ngIf="!isExternalReservation() && isPendingConfirmation()" (click)="cancelChange()"
        class="btn btn-green btn-big left-margin top-margin"><span class="glyphicon glyphicon-align-right"></span> Cancel change</button>
      <button type="button" (click)="openRefundModal()" class="btn btn-green btn-big left-margin top-margin"><span
          class="glyphicon glyphicon-share-alt"></span> Refund</button>
      <button type="button" (click)="changeReservation()" *ngIf="!isExternalReservation() && !isPendingConfirmation()"
        class="btn btn-green btn-big left-margin top-margin"><span class="glyphicon glyphicon-align-right"></span> Change reservation</button>
      <button type="button" (click)="viewGuests()" class="btn btn-green btn-big left-margin top-margin"><span
          class="glyphicon glyphicon-align-right"></span> View guests</button>
      <button type="button" (click)="openCancelReservationModal()" *ngIf="!isPendingConfirmation()"
        class="btn btn-green btn-big left-margin top-margin" [disabled]="isCancelled()"><span class="glyphicon glyphicon-align-right"></span> Cancel
        reservation</button>
    </div>
  </div>

  <div class="row mt" *ngIf="editAllowed">
    <div class="col-md-6">
      <div class="normal-checkbox">
        <input type="checkbox" [checked]="reservation.notifyCustomer" (click)="changeNotifyCustomer($event)">
        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
        <div class="checkbox-label">Send notifications</div>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <button type="button" [disabled]="isCancelled() || isPendingConfirmation()" class="btn btn-green btn-big"
        (click)="openMessageTypeModal()">Send message</button>
    </div>
  </div>

  <div class="row contact-person-info">
    <div class="col-md-6">
      <h2 style="margin-top: 40px;margin-bottom: 10px">Contact person information<button *ngIf="editAllowed"
          (click)="openCustomerEditModal()" class="btn btn-green left-margin"><span
            class="glyphicon glyphicon-edit"></span> Edit</button></h2>
      <div class="row">
        <div>
          <div class="col-xs-5">Full name:</div>
          <div class="col-xs-7">{{reservation.customer.firstName + ' ' + reservation.customer.lastName}}</div>
        </div>
        <div>
          <div class="col-xs-5">Real email:</div>
          <div class="col-xs-7"><a [routerLink]="['/customer-card/',reservation.customer.emailReal,reservation.customer.mobile.substring(1,reservation.customer.mobile)]">{{reservation.customer.emailReal ? reservation.customer.emailReal : 'n/a'}}</a></div>
        </div>
        <div [hidden]="!isBookingChannel">
          <div class="col-xs-5">Virtual email:</div>
          <div class="col-xs-7">{{reservation.customer.emailVirtual ? reservation.customer.emailVirtual : 'n/a'}}</div>
        </div>
        <div>
          <div class="col-xs-5">Mobile:</div>
          <div class="col-xs-7">{{reservation.customer.mobile ? reservation.customer.mobile : 'n/a'}}</div>
        </div>
        <div>
          <div class="col-xs-5">Nationality:</div>
          <div class="col-xs-7">{{getCountryLabelBasedOnCode(reservation.customer.nationality)}}</div>
        </div>
        <ng-container *ngIf="hasNationality">
          <div *ngIf="isSSN">
            <div class="col-xs-5">SSN:</div>
            <div class="col-xs-7">{{reservation.customer.ssn ? reservation.customer.ssn : 'n/a'}}</div>
          </div>
          <div *ngIf="!isSSN">
            <div class="col-xs-5" >Passport number:</div>
            <div class="col-xs-7">{{reservation.customer.passportNumber ? reservation.customer.passportNumber : 'n/a'}}</div>
          </div>
        </ng-container>
        <div>
          <div class="col-xs-5">Address:</div>
          <div class="col-xs-7">{{reservation.customer.address ? reservation.customer.address : 'n/a'}}</div>
        </div>
        <div>
          <div class="col-xs-5">Postal code:</div>
          <div class="col-xs-7">{{reservation.customer.postalCode ? reservation.customer.postalCode : 'n/a'}}</div>
        </div>
        <div>
          <div class="col-xs-5">City:</div>
          <div class="col-xs-7">{{reservation.customer.city ? reservation.customer.city : 'n/a'}}</div>
        </div>
        <div>
          <div class="col-xs-5">Organization:</div>
          <div class="col-xs-7">{{reservation.company.name ? reservation.company.name : 'n/a'}}</div>
        </div>
        <div>
          <div class="col-xs-5">Campaign code:</div>
          <div class="col-xs-7">{{campaignCodes(reservation.orders)}}</div>
        </div>
        <div>
          <div class="col-xs-5">Segment:</div>
          <div class="col-xs-7">{{reservation.reservationSegment ? reservation.reservationSegment : "n/a"}}</div>
        </div>
        <div>
          <div class="col-xs-5">Booking channel:</div>
          <div class="col-xs-7">{{ reservation.bookingChannel }}</div>
        </div>
        <div *ngIf="reservation.bookingChannelReservationId">
          <div class="col-xs-5">Ext. channel Id: </div>
          <div class="col-xs-7">{{ reservation.bookingChannelReservationId }}</div>
        </div>
        <div>
          <div class="col-xs-5">
            <div style="position: relative; top: 5px">Language:</div>
          </div>
          <div class="col-xs-7">
            <dropdown-menu
              [menuId]="'edit-reservation-lang'"
              [menuList]="langList"
              [selectedItem]="selectedLang"
              [disableMenu]="changingLang"
              [fullWidth]="true"
              (selectItem)="selectReservationLang($event)"
            ></dropdown-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h2 style="margin-top: 40px;margin-bottom: 10px">Comments</h2>
      <div class="comment-box" *ngIf="comments">
        <div class="comment-item" *ngFor="let comment of comments">
          <div class="info-container">
            <span class="info-email">{{comment.author.name}}</span>
            <span class="info-time">{{utils.convertISOFullDate(comment.timestamp)}}</span>
          </div>
          <div class="info-content">{{comment.data.comment}}</div>
        </div>
      </div>
      <div class="comment-input-container" [formGroup]="commentForm">
        <textarea class="form-control" rows="2" placeholder="Write a comment..."
          formControlName="commentInput"></textarea>
        <button class="btn btn-green" [disabled]="disabledBtnWhileCallingAPI" (click)="saveComment()"><span class="glyphicon glyphicon-comment"></span>
          Add</button>
      </div>
    </div>
  </div>
  <div class="wrapper-activity-log">
    <div class="log-item">
      <h2>{{reservation.startDate | date:'dd.MM.yyyy'}} - {{reservation.endDate | date:'dd.MM.yyyy'}}</h2>
    </div>
    <div style="flex: 0 0 200px">
      <dropdown-menu
        [menuId]="'summary-view'"
        [menuList]="summaryView"
        [selectedItem]="selectedSummaryView"
        [fullWidth]="true"
        (selectItem)="selectSummaryView($event)"
      ></dropdown-menu>
    </div>
    <div style="flex: 0 1 auto">
      <button (click)="this.handleClickActivityBtn()" routerLink="/activity-log/{{reservation.reservationCode}}/{{reservation.uuid}}" type="button" class="btn btn-outline-green height-40">
        <img class="brand-login-logo" src="assets/images/log.svg">
        Activity log
      </button>
    </div>
  </div>

  <!-- All products summary table -->

  <div class="custom-table-wrapper" *ngIf="selectedSummaryView.value === 'allProducts'">
    <table class="custom-table summary-table">
      <thead>
        <tr class="dark-bg">
          <th>Product</th>
          <th class="text-right">Units</th>
          <th class="text-right">Rooms</th>
          <th class="text-right">Unit price</th>
          <th class="text-right">Price</th>
          <th class="text-right">List price</th>
        </tr>
      </thead>
      <tbody *ngIf="orders">
        <ng-container *ngFor="let product of orders.productsInReservation">
          <tr *ngIf="getTotalSummary('price', product.productId) != 0 && getTotalSummary('count', product.productId) > 0">
            <td>
              <ng-container *ngIf="orders.orderProductOffer">{{utils.showRoomTitle(getProductName(product.productId, -1))}}</ng-container>
            </td>
            <td class="text-right">
              {{getTotalSummary('count', product.productId)}}
            </td>
            <td class="text-right">
              {{getUnitRoomSummary(product.productId)}}
            </td>
            <td class="text-right">
              <input type="text" appEditPrice (input)="markEdited()"
                (blur)="unitSummaryChange(product.productId, $event)"
                value="{{getUnitSummary(product.productId) | currencyFormat:'price'}}"
                *ngIf="!isEditDisabled()">
              <span *ngIf="isEditDisabled()"> {{getUnitSummary(product.productId) | currencyFormat:"price"}}</span>
              <span> {{getTotalSummary('price', product.productId) | currencyFormat:"currency"}}</span>
            </td>
            <td class="text-right">
              <input type="text" appEditPrice (input)="markEdited()"
                (blur)="summaryChange(product.productId, getTotalSummary('price', product.productId), $event)"
                value="{{getTotalSummary('price', product.productId) | currencyFormat:'price'}}"
                *ngIf="!isEditDisabled()">
              <span *ngIf="isEditDisabled()">{{getTotalSummary('price', product.productId) |
                currencyFormat:"price"}}</span>
              <span> {{getTotalSummary('price', product.productId) | currencyFormat:"currency"}}</span>
            </td>
            <td class="text-right">{{getTotalSummary('listPrice', product.productId) | currencyFormat:"fullPrice"}}</td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="total-row">
          <th colspan="4">Reservation total</th>
          <th class="text-right">{{getTotalSummary('totalPrice') | currencyFormat:"fullPrice"}}</th>
          <th class="text-right">{{getTotalSummary('totalListPrice') | currencyFormat:"fullPrice"}}</th>
        </tr>
        <tr class="total-row">
          <td class="text-left" colspan="4">Total non-refundable items</td>
          <td class="text-right">{{getTotalSummary('totalNonRefundablePrice') | currencyFormat:"fullPrice"}}</td>
          <td class="text-right">{{getTotalSummary('totalNonRefundableListPrice') | currencyFormat:"fullPrice"}}</td>
        </tr>
      </tfoot>
    </table>
  </div>

  <!-- Extras summary table -->
  <div class="custom-table-wrapper" *ngIf="orders && orders.extrasSummary.length && selectedSummaryView.value === 'extras'">
    <table class="custom-table summary-table">
      <thead>
        <tr class="dark-bg">
          <th>Product</th>
          <th class="text-right" style="width: 200px;">Unit price</th>
          <th class="text-right" style="width: 200px;">Price</th>
          <th class="text-right" style="width: 200px;">List price</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let product of orders.extrasSummary">
          <tr class="black-border">
            <td>
              <b>{{utils.showRoomTitle(getProductName(product.productId, -1))}} x {{product.amount}}</b>
            </td>
            <td class="text-right">
              <b>
                <input type="text" appEditPrice (input)="markEdited()"
                  (blur)="unitSummaryChange(product.productId, $event)"
                  value="{{product.unitPrice | currencyFormat:'price'}}"
                  *ngIf="!isEditDisabled()">
                <span *ngIf="isEditDisabled()"> {{product.unitPrice | currencyFormat:"price"}}</span>
                <span> {{product.unitPrice | currencyFormat:"currency"}}</span>
              </b>
            </td>
            <td class="text-right">
              <b>
                <input type="text" appEditPrice (input)="markEdited()"
                  (blur)="summaryChange(product.productId, product.price, $event)"
                  value="{{product.price | currencyFormat:'price'}}"
                  *ngIf="!isEditDisabled()">
                <span *ngIf="isEditDisabled()"> {{product.price | currencyFormat:"price"}}</span>
                <span> {{product.price | currencyFormat:"currency"}}</span>
              </b>
            </td>
            <td class="text-right">
              <b>
                <span> {{product.listPrice | currencyFormat:"price"}}</span>
                <span> {{product.listPrice | currencyFormat:"currency"}}</span>
              </b>
            </td>
          </tr>
          <ng-container *ngFor="let date of product.dates">
            <tr>
              <td>
                {{date.date | date: 'dd.MM.YYYY'}} x {{date.amount}}
              </td>
              <td class="text-right">
                <input type="text" appEditPrice (input)="markEdited()"
                  (blur)="extraSummaryChange('unit', date.amount, product.productId, date.date, $event)"
                  value="{{date.unitPrice | currencyFormat:'price'}}"
                  *ngIf="!isEditDisabled()">
                <span *ngIf="isEditDisabled()"> {{date.unitPrice | currencyFormat:"price"}}</span>
                <span> {{date.unitPrice | currencyFormat:"currency"}}</span>
              </td>
              <td class="text-right">
                <input type="text" appEditPrice (input)="markEdited()"
                  (blur)="extraSummaryChange('total', date.amount, product.productId, date.date, $event)"
                  value="{{date.price | currencyFormat:'price'}}"
                  *ngIf="!isEditDisabled()">
                <span *ngIf="isEditDisabled()"> {{date.price | currencyFormat:"price"}}</span>
                <span> {{date.price | currencyFormat:"currency"}}</span>
              </td>
              <td class="text-right">
                <span> {{date.listPrice | currencyFormat:"price"}}</span>
                <span> {{date.listPrice | currencyFormat:"currency"}}</span>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="total-row">
          <th colspan="2">Total</th>
          <th class="text-right">
            <span> {{getExtrasTotalSummary('price') | currencyFormat:"price"}}</span>
            <span> {{getExtrasTotalSummary('price') | currencyFormat:"currency"}}</span>
          </th>
          <th class="text-right">
            <span> {{getExtrasTotalSummary('list') | currencyFormat:"price"}}</span>
            <span> {{getExtrasTotalSummary('list') | currencyFormat:"currency"}}</span>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>

  <!-- Stay range summary table -->
  <div class="custom-table-wrapper" *ngIf="orders && orders.dateRangeSummary.length && selectedSummaryView.value === 'stayRange'">
    <table class="custom-table summary-table">
      <thead>
        <tr class="dark-bg">
          <th>Check-in</th>
          <th>Check-out</th>
          <th class="text-right" style="width: 200px;">Unit price</th>
          <th class="text-right" style="width: 200px;">Price</th>
          <th class="text-right" style="width: 200px;">List price</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let product of orders.dateRangeSummary">
          <tr class="black-border">
            <td>
              <b>{{product.startDate}}</b>
            </td>
            <td>
              <b>{{product.endDate}}</b>
            </td>
            <td class="text-right"></td>
            <td class="text-right">
              <span> {{product.price | currencyFormat:"price"}}</span>
              <span> {{product.price | currencyFormat:"currency"}}</span>
            </td>
            <td class="text-right">
              <span> {{product.listPrice | currencyFormat:"price"}}</span>
              <span> {{product.listPrice | currencyFormat:"currency"}}</span>
            </td>
          </tr>
          <ng-container *ngFor="let item of product.products">
            <tr>
              <td colspan="2">
                {{utils.showRoomTitle(getProductName(item.productId, -1))}} x {{item.amount}}
              </td>
              <td class="text-right">
                <span> {{item.unitPrice | currencyFormat:"price"}}</span>
                <span> {{item.unitPrice | currencyFormat:"currency"}}</span>
              </td>
              <td class="text-right">
                <span> {{item.price | currencyFormat:"price"}}</span>
                <span> {{item.price | currencyFormat:"currency"}}</span>
              </td>
              <td class="text-right">
                <span> {{item.listPrice | currencyFormat:"price"}}</span>
                <span> {{item.listPrice | currencyFormat:"currency"}}</span>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="total-row">
          <th colspan="3">Total</th>
          <th class="text-right">
            <span> {{getDateRangeTotalSummary('price') | currencyFormat:"price"}}</span>
            <span> {{getDateRangeTotalSummary('price') | currencyFormat:"currency"}}</span>
          </th>
          <th class="text-right">
            <span> {{getDateRangeTotalSummary('list') | currencyFormat:"price"}}</span>
            <span> {{getDateRangeTotalSummary('list') | currencyFormat:"currency"}}</span>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
  <!-- <div class="text-right">
    <button type="button" class="btn btn-green btn-big format-for-btn add-payment" (click)="openAdditionalServicesModal()" *ngIf="!isEditDisabled()"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span>Add additional services</button>
  </div> -->

  <h2 style="margin-bottom: 10px;">Payments</h2>
  <div class="row" style="margin-bottom: 10px;font-size: 14pt;">
    <div class="col-md-3"><b>Reference number:</b></div>
    <div class="col-md-3">{{getReferenceNumber('code')}}</div>
    <div class="col-md-3">{{getReferenceNumber('number')}}</div>
  </div>
  <div class="custom-table-wrapper">
    <table class="custom-table">
      <thead>
        <tr class="dark-bg">
          <th>Date</th>
          <th>Amount</th>
          <th>Payment provider</th>
          <th>Payment method / Payment reference</th>
          <th *ngIf="hasRefund">Refund note</th>
        </tr>
        <tr class="total-row">
          <th>Total paid</th>
          <th class="text-right">{{reservation.totalPaid | currencyFormat:'fullPrice'}}</th>
          <th></th>
          <th></th>
          <th *ngIf="hasRefund"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let payment of payments; ; let i = index">
          <tr (click)="checkInvoice(payment.fennoaInvoiceId, i)" [ngClass]="{'clickable': payment.fennoaInvoiceId}">
            <td class="">{{utils.convertStringDate(payment.paymentDate)}}</td>
            <td class="text-right">{{payment.amount | currencyFormat:'fullPrice'}}</td>
            <td>{{ payment.manualPaymentProvider ? payment.manualPaymentProvider : (payment.paymentProvider === 'MONETARY_VOUCHER' && isGiftCardPayment(payment.paymentMethod) ? "Gift card" : payment.paymentProvider | paymentProviderFilter ) }}</td>
            <td class="text-left">
              <ng-container *ngIf="payment.manualPaymentProvider">{{ payment.paymentReference }}</ng-container>
              <ng-container *ngIf="!payment.manualPaymentProvider && !isGiftCardPayment(payment.paymentMethod)">{{ payment.paymentMethod }}</ng-container>
            </td>
            <td *ngIf="hasRefund" style="max-width: 300px">
              <div *ngIf="payment.isRefund" class="refund-cell">
                <div class="refund-reason" tooltip="{{ !isGiftCardPayment(payment.paymentMethod) ? payment.reason : payment.paymentMethod }}" placement="bottom">
                  <ng-container *ngIf="!isGiftCardPayment(payment.paymentMethod)">
                    <div class="payment-reason">{{ payment.paymentStatus === 'REFUND_ERROR' ? "Refund error" : payment.reason }}</div>
                  </ng-container>
                  <ng-container *ngIf="isGiftCardPayment(payment.paymentMethod)">
                    <div class="payment-method-gift-card" (click)="openVoucher(payment.paymentMethod)">{{ payment.paymentMethod }}</div>
                  </ng-container>
                </div>
                <ng-container *ngIf="payment.paymentStatus == 'MANUAL_REFUND'">
                  <div class="refund-icon-hover" tooltip="Pending refund" placement="bottom"
                    (click)="openManualRefundModal(payment.paymentId)"><span
                      class="glyphicon glyphicon-warning-sign"></span></div>
                </ng-container>
                <ng-container *ngIf="payment.paymentStatus == 'COMPLETED'">
                  <div tooltip="Refund completed" placement="bottom"><span class="glyphicon glyphicon-saved"></span></div>
                </ng-container>
              </div>
            </td>
            <!-- <td class="text-left" *ngIf="payment.isRefund && isGiftCardPayment(payment.paymentMethod)">{{ payment.reason }}</td> -->
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="dark-bg">
          <td class="text-left">{{getPriceNumber(totalBalance) < 0 ? 'Balance to be refunded' : 'Balance to be paid' }}</td>
          <td class="text-right">{{totalBalance | currencyFormat:'fullPrice'}}</td>
          <td></td>
          <td></td>
          <td *ngIf="hasRefund"></td>
        </tr>
      </tfoot>
    </table>
  </div>

  <div class="text-right">
    <span class="icon-download" *ngIf="hasPdf" (click)="showOptionsForCreditInvoiceModal()">
      <img class="brand-login-logo" src="assets/images/icon-download.svg">
    </span>
    <button type="button" class="btn btn-green btn-big format-for-btn add-payment" (click)="openPaymentTypeModal()" *ngIf="paymentAllowed"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span>Add payment</button>
    <button type="button" class="btn btn-green btn-big format-for-btn proceed-to-refund" (click)="proceedToRefund()"
      *ngIf="getPriceNumber(totalBalance) < 0 && paymentAllowed"><span class="glyphicon glyphicon-share-alt"></span> Proceed to
      refund</button>
  </div>

  <div class="normal-checkbox" style="margin-bottom: 30px">
    <input type="checkbox" [checked]="showCancelled" (click)="toggleShowCancelled($event)">
    <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
    <div class="checkbox-label">Show cancelled items</div>
  </div>

  <ng-container *ngFor="let order of reservation.orders; let i = index">
    <h2 style="margin-bottom: 20px;">
      Room {{order.roomNumber}} - Date: {{order.startDate}} - {{order.endDate}}<span *ngIf="isOrderCancelled(i)"> -
        Cancelled!</span>
      <span class="glyphicon glyphicon-info-sign special-icon" tooltip="Room for disabled" placement="bottom"
        *ngIf="order.forDisabled"></span>
      <!-- <span class="glyphicon glyphicon-baby-formula special-icon" tooltip="Room has children" placement="bottom"
        *ngIf="order.thereChildren"></span> -->
    </h2>
    <div class="row doorcode-wrapper" [attr.data-order]="order.id" style="margin-bottom: 20px;font-size: 14pt;">
      <ng-container *ngIf="hotelConfig.feature.showDoorInfo">
        <div class="col-sm-2" style="padding-top:5px"><b>Door code:</b></div>
        <div class="col-sm-2" style="padding-top:5px">
          <span *ngIf="!order.hasDoorCode">-</span>
          <span class="door-code" *ngIf="order.hasDoorCode">•••••</span>
        </div>
        <div class="col-sm-6">
          <button class="btn btn-green" *ngIf="order.hasDoorCode && !doorCodeTimeout['show'+order.id]"
            (click)="showDoorCode(order.id)">
            <span class="glyphicon glyphicon-sunglasses"></span> Show
          </button>
          <button class="btn btn-green code-toggle"
            *ngIf="order.hasDoorCode && doorCodeTimeout['show'+order.id]" (click)="setDoorCode('hide', order.id)">
            <span class="glyphicon glyphicon-eye-close"></span> Hide
            <!-- <div class="timeout-bar">
              <div class="bar"></div>
            </div> -->
          </button>
          <button class="btn btn-green" *ngIf="editAllowed"
            (click)="openResetAlert(order.roomNumber, order.id, reservation.reservationCode, reservation.uuid)"><span
              class="glyphicon glyphicon-refresh"></span> Reset</button>
        </div>
      </ng-container>
      <ng-container *ngIf="!hotelConfig.feature.showDoorInfo">
        <div class="col-sm-10"></div>
      </ng-container>
      <div class="col-sm-2 see-usage" *ngIf="editAllowed">
        <a (click)="openUsage(order.roomRequest.id)">See usage</a>
      </div>
    </div>
    <div class="custom-table-wrapper">
      <table class="custom-table" *ngIf="reservation.guests">
        <thead>
          <tr class="dark-bg">
            <th>Guest name</th>
            <!-- <th>Age</th> -->
            <th>Email</th>
            <th>Phone number</th>
            <th>SSN / Passport number</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let guest of getGuestListBasedOnAlias(order.roomAlias);let guestIndex = index">
            <ng-template #checkinTooltipHover>
              <div class="checkin-tooltip">
                <div><b>Checked-in</b></div>
                <div>{{convertDate(guest.completedCheckIn)}}</div>
              </div>
            </ng-template>
            <ng-template #notCheckinTooltipHover>
              <div class="checkin-tooltip">
                <div><b>Not checked-in yet</b></div>
              </div>
            </ng-template>
            <ng-template #childCheckinTooltipHover>
              <div class="checkin-tooltip">
                <div><b>Underaged guest</b></div>
              </div>
            </ng-template>
            <tr *ngIf="isRoomEmpty(order.roomAlias) && guestIndex === 0">
              <td (click)="editGuestsInBUI(guest.id)">
                <div class="checkin-item">
                  <div class="checkin-item-check" [tooltip]="notCheckinTooltipHover" [adaptivePosition]="false" placement="top" *ngIf="guest.firstName">
                    <img width="16px" height="16px" src="assets/images/exclamation.png" style="position:relative; top:2px">
                  </div>
                  <div>
                    {{reservation.customer.firstName}} {{reservation.customer.lastName}}
                  </div>
                </div>
              </td>
              <td *ngIf="!reservation.customer.emailReal && !reservation.customer.emailVirtual">n/a</td>
              <td *ngIf="reservation.customer.emailReal || reservation.customer.emailVirtual"><a [routerLink]="['/customer-card/',reservation.customer.emailReal,reservation.customer.mobile.substring(1,reservation.customer.mobile)]">{{reservation.customer.emailReal ? reservation.customer.emailReal : reservation.customer.emailVirtual}}</a></td>
              <td>{{reservation.customer.mobile ? reservation.customer.mobile : 'n/a'}}</td>
              <td>{{ reservation.customer.nationality === "FIN" ? (reservation.customer.ssn ? reservation.customer.ssn : 'n/a') : (reservation.customer.passportNumber ? reservation.customer.passportNumber : 'n/a')}}</td>
            </tr>
            <tr *ngIf="!isRoomEmpty(order.roomAlias) || (isRoomEmpty(order.roomAlias) && guestIndex !== 0)">
              <td *ngIf="guest.completedCheckInOnline === true" (click)="editGuestsInBUI(guest.id)">
                <div class="checkin-item">
                  <div class="checkin-item-check" [tooltip]="checkinTooltipHover" [adaptivePosition]="false" placement="top">
                    <img src="assets/images/confirm.svg">
                  </div>
                  <div>
                    {{guest.firstName}} {{guest.lastName}}
                  </div>
                </div>
              </td>
              <td *ngIf="guest.completedCheckInOnline !== true" (click)="editGuestsInBUI(guest.id)">
                <div class="checkin-item">
                  <div class="checkin-item-check" [tooltip]="notCheckinTooltipHover" [adaptivePosition]="false" placement="top" *ngIf="guest.firstName && !isGuestUnderaged(guest.age)">
                    <img width="16px" height="16px" src="assets/images/exclamation.png" style="position:relative; top:2px">
                  </div>
                  <div class="checkin-item-check" [tooltip]="childCheckinTooltipHover" [adaptivePosition]="false" placement="top" *ngIf="guest.firstName && isGuestUnderaged(guest.age)">
                    <img width="auto" height="20px" src="assets/images/child-icon.png" style="position:relative; top:2px">
                  </div>
                  <div>
                    {{guest.firstName}} {{guest.lastName}}
                  </div>
                </div>
              </td>
              <!-- <td>{{guest.age ? guest.age : 'n/a'}} <span class="glyphicon glyphicon-baby-formula red-text"
                  tooltip="Child" placement="right" *ngIf="guest.age < 18 && guest.age != null"></span></td> -->
              <td *ngIf="!guest.email">n/a</td>
              <td *ngIf="guest.email"><a [routerLink]="['/customer-card/',guest.email,guest.mobile ? guest.mobile.substring(1,guest.mobile) : '']">{{guest.email}}</a></td>
              <td>{{guest.mobile ? guest.mobile : 'n/a'}}</td>
              <td>{{ guest.nationality === "FIN" ? (guest.ssn ? guest.ssn : 'n/a') : (guest.passportNumber ? guest.passportNumber : 'n/a')}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="custom-table-wrapper">
      <table class="custom-table safari-table z-index-format" *ngIf="!isCancelled()">
        <colgroup>
          <col>
          <col style="width: 331px">
          <col>
          <col>
          <col style="width: 150px">
          <col style="width: 150px">
        </colgroup>
        <thead class="sticky">
          <tr class="dark-bg">
            <th><div class="padding">Product</div></th>
            <th><div class="padding">Date</div></th>
            <th><div class="padding">Price</div></th>
            <th><div class="padding">List price</div></th>
            <th><div class="padding">Non-refundable</div></th>
            <th><div class="padding">Cancel</div></th>
          </tr>
        </thead>
        <tbody class="z-index-format">
          <ng-container *ngFor="let totalItem of filterInvoiceTypeForOrders(orders.roomOrders[i]); let productIndex = index">
            <tr class="total-row sticky one-row" *ngIf="totalItem.count > 0 || showCancelled">
              <th colspan="2" *ngIf="orders" (click)="toggleOrderProductAccordion('order' + i + 'product' + productIndex)">
                <div class="padding">
                  <span class="glyphicon glyphicon-chevron-down" [ngClass]="{'rotate': orderProductAccordion.includes('order' + i + 'product' + productIndex)}"></span>
                  {{utils.showRoomTitle(getProductName(totalItem.productId, i))}} x {{totalItem.count}}
                </div>
              </th>
              <th class="text-right">
                <div class="padding">
                  <span style="width: 150px">
                    <input type="text" appEditPrice (input)="markEdited()"
                    (blur)="productTotalChange(i, totalItem.productId, totalItem.price, $event)"
                    value="{{totalItem.price | currencyFormat:'price'}}" *ngIf="!isEditDisabled() && totalItem.price != 0">
                    <span *ngIf="isEditDisabled()">{{totalItem.price | currencyFormat:"price"}}</span>
                    <span> {{totalItem.price | currencyFormat:"currency"}}</span>
                  </span>
                </div>
              </th>
              <th class="text-right">
                <div class="padding"><span style="width: 100px">{{totalItem.listPrice | currencyFormat:'fullPrice'}}</span></div></th>
              <th></th>
              <th></th>
            </tr>
            <ng-container *ngIf="orderProductAccordion.includes('order' + i + 'product' + productIndex)">
              <ng-container *ngFor="let lineItem of order.lineItems; let itemIndex = index">
                <ng-container *ngIf="lineItem.productId == totalItem.productId">
                  <tr *ngIf="!lineItem.cancelled || (lineItem.cancelled && showCancelled)">
                    <td class="text-right" colspan="2">
                      <div class="padding">
                        <span class="glyphicon glyphicon-{{getState(lineItem)}}"></span>
                        {{lineItem.serviceDate | date:'dd.MM.yyyy'}}
                      </div>
                    </td>
                    <td class="text-right">
                      <div class="padding">
                        <span style="width: 150px">
                          <input type="text" appEditPrice (input)="markEdited()"
                          (blur)="productPriceChange(i, lineItem.id, lineItem.price.amount, $event, itemIndex)"
                          value="{{lineItem.price.amount | currencyFormat:'price'}}"
                          *ngIf="!isEditDisabled()">
                          <span *ngIf="isEditDisabled()">{{lineItem.price.amount
                            | currencyFormat:"price"}}</span>
                          <span> {{lineItem.price.amount | currencyFormat:"currency"}}</span>
                        </span>
                      </div>
                    </td>
                    <td class="text-right">
                      <div class="padding"><span style="width: 100px">{{lineItem.listPrice.amount | currencyFormat:'price'}} {{utils.getCurrency(currency)}}</span></div>
                    </td>
                    <td><div class="padding"><input class="table-checkbox" type="checkbox" disabled [checked]="!lineItem.refundable"></div></td>
                    <td><div class="padding">
                      <input class="table-checkbox" type="checkbox" (click)="toggleItemCancel(i, itemIndex, $event)"
                          [(ngModel)]="lineItem.toBeCancelled" [disabled]="isEditDisabled()" *ngIf="!lineItem.cancelled">
                    </div></td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
        
        <tfoot *ngIf="!isCancelled() && orders.orderProductOffer" class="z-index-format">
          <tr class="total-row" *ngIf="!isEditDisabled() ||isAllowExternalReservationAddExtra()">
            <td class="text-left extra-secif">
              <div style="padding: 0 10px; width: 200px">
                <div class="btn-group mui-menu full-width" dropdown>
                  <button dropdownToggle type="button" class="btn dropdown-toggle" style="background-color: #D2D6E0;">
                    {{changeLabelForExtraDropdown(i,orderProductOfferLabel) || ''}} <span class="caret"></span>
                  </button>
                  <div *dropdownMenu class="dropdown-menu" role="menu">
                    <div class="menu-item-scroll">
                      <ng-container *ngFor="let product of orders.orderProductOffer[i]">
                        <ng-container *ngIf="product.type != 'SINGLE' && product.type != 'ACCOMMODATION' && product.type != 'COMPENSATE'">
                          <div class="menu-item" (click)="selectProductOffer(i, product)" [ngClass]="{'selected': product.title===orderProductOfferLabel[i]}">
                            {{ changeNameForProduct(product, i, orderProductOfferLabel[i]) }}
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="padding">
                <div class="warning-icon" *ngIf="orderOfferSelection[i].notAvailable" tooltip="Not available on this date"
                  placement="bottom"><span class="glyphicon glyphicon-warning-sign"></span></div>
                <div class="datepicker-input big-icon">
                  <input type="text" placeholder="Select date" class="form-control" (ngModelChange)="selectProductOffer(i)"
                    bsDatepicker [(ngModel)]="orderOfferSelection[i].selectedDate"
                    [isOpen]="orderDatepicker[order.id].opened" [minDate]="utils.newDate(reservation.startDate)"
                    [maxDate]="utils.newDate(reservation.endDate)"
                    [bsConfig]="{ isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', adaptivePosition: true }">
                  <button type="button" (click)="orderDatepicker[order.id].opened = !orderDatepicker[order.id].opened">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </button>
                </div>
              </div>
            </td>
            <td>
              <div class="padding">
                <ng-container *ngIf="orderOfferSelection[i].price">
                  <input appEditPrice type="text" (input)="offerPriceChange($event, i)"
                    [value]="orderOfferSelection[i].price | currencyFormat:'price'">
                  <span> {{orderOfferSelection[i].price | currencyFormat:'currency'}}</span>
                </ng-container>
              </div>
            </td>
            <td>
              <div class="padding">
                <span *ngIf="orderOfferSelection[i].listPrice">{{orderOfferSelection[i].listPrice |
                  currencyFormat:'fullPrice'}}</span>
              </div>
            </td>
            <td></td>
            <td>
              <div class="padding">
                <button type="button" class="btn btn-green btn-big" [disabled]="!orderOfferSelection[i].enableAdd"
                  (click)="addOfferToOrder(i)"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span>Add</button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </ng-container>
  
  <ng-container *ngIf="!isEditDisabled() && inputEdited || isAllowExternalReservationAddExtra() && inputEdited">
    <div class="text-right" style="margin-bottom: 20px">
      <button type="button" (click)="saveReservation()" [disabled]="disabledBtnWhileCallingAPI" class="btn btn-green btn-big left-margin"><span
        class="glyphicon glyphicon-save"></span> Save changes</button>
      <button type="button" (click)="revertReservation()" class="btn btn-green btn-big left-margin"><span
        class="glyphicon glyphicon-step-backward"></span> Revert changes</button>
    </div>
  </ng-container>

</div>
<app-footer></app-footer>

<!-- Reset Door Code -->
<ng-template #resetDoorCodeModal>
  <div class="modal-body text-center">
    <div class="error-icon"><span class="glyphicon glyphicon-question-sign"></span></div>
    <div class="error-title">Reset door code</div>
    <div class="error-desc">Are you sure that you want to reset room {{resetDoorCodeValue.roomNumber}} door code?</div>
    
    <!-- Loading icon -->
    <div class="center-item" *ngIf="isLoadingDoorCode">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>

    <button type="button" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="btn custom-button btn-green" [disabled]="isLoadingDoorCode"
      (click)="resetDoorCode(resetDoorCodeValue.orderId,resetDoorCodeValue.uuid)">OK</button>
  </div>
</ng-template>

<!-- Allow for manually ending a on-going room usage earlier than the original end time -->
<ng-template #overlapOnGoingRoomUsage>
  <div class="modal-body text-center">
    <div class="error-icon"><span class="glyphicon glyphicon-question-sign"></span></div>
    <div class="error-title">Ongoing reservation</div>
    <div class="error-desc">Do you want allow guest to enter anyway?</div>

    <button type="button" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="custom-button btn-green"
      (click)="overlapOnGoingReservation()">OK</button>
  </div>
</ng-template>

<!-- Complete manual refund -->
<ng-template #manualRefundModal>
  <div class="modal-body text-center">
    <div class="error-icon"><span class="glyphicon glyphicon-question-sign"></span></div>
    <div class="error-title">Manual refund</div>
    <div class="error-desc">Are you sure that you want to mark this as completed?</div>

    <button type="button" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="custom-button btn-green" (click)="completeManualRefund()">OK</button>
  </div>
</ng-template>

<!--Customer edit modal-->
<ng-template #customerEditModal >
  <div class="modal-header">
    <div class="modal-title pull-left">Customer</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="customerEditForm">
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>First Name</b>
        </div>
        <div class="col-md-12">
          <input class="custom-input form-control" [ngClass]="{'invalid': firstName.invalid}" formControlName="firstName"
            type="text">
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>Last Name</b>
        </div>
        <div class="col-md-12">
          <input class="custom-input form-control" [ngClass]="{'invalid': lastName.invalid}" formControlName="lastName" type="text">
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>Real Email</b>
        </div>
        <div class="col-md-12">
          <input class="custom-input form-control" [ngClass]="{'invalid': realEmail.invalid}" formControlName="realEmail" type="text">
          <div class="input-error-desc" *ngIf="realEmail.invalid && editCustomerBlocked.includes('EMAIL')">Email is blocked</div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>Mobile</b>
        </div>
        <div class="col-md-12">
          <input class="custom-input form-control" [ngClass]="{'invalid': mobile.invalid}" formControlName="mobile" type="text">
          <div class="input-error-desc" *ngIf="mobile.invalid && editCustomerBlocked.includes('MOBILE')">Mobile is blocked</div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>Nationality</b>
        </div>
        <div class="col-md-12">
          <dropdown-menu
            [menuId]="'edit-customer-nationality'"
            [menuList]="countryList"
            [selectedItem]="selectedEditCustomerCountry"
            [fullWidth]="true"
            [searchable]="true"
            (selectItem)="editCustomerSelectCountry($event)"
          ></dropdown-menu>
        </div>
      </div>
      <ng-template [ngIf]="hasNationality">
        <div class="row no-gutters" [hidden]="!isSSNEdit">
          <div class="col-md-3">
            <b>SSN</b>
          </div>
          <div class="col-md-12">
            <input class="custom-input form-control" formControlName="ssn" type="text" [ngClass]="{'invalid': ssn.invalid}">
            <div class="input-error-desc" *ngIf="ssn.invalid && editCustomerBlocked.includes('SSN')">SSN is blocked</div>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="hasNationality">
        <div class="row no-gutters" [hidden]="isSSNEdit">
          <div class="col-md-12">
            <b>Passport number</b>
          </div>
          <div class="col-md-12">
            <input class="custom-input form-control" formControlName="passportNumber" type="text" [ngClass]="{'invalid': passportNumber.invalid}">
            <div class="input-error-desc" *ngIf="passportNumber.invalid && editCustomerBlocked.includes('PASSPORT_NUMBER')">Passport number is blocked</div>
          </div>
        </div>
      </ng-template>
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>Address</b>
        </div>
        <div class="col-md-12">
          <input class="custom-input form-control" formControlName="address" type="text">
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>Postal Code</b>
        </div>
        <div class="col-md-12">
          <input class="custom-input form-control" formControlName="postalCode" type="text">
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>City</b>
        </div>
        <div class="col-md-12">
          <input class="custom-input form-control" formControlName="city" type="text">
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12">
          <b>Organization</b>
        </div>
        <div class="col-md-12">
          <input class="custom-input" formControlName="companyName" type="text">
        </div>
      </div>
      <div class="row no-gutters text-right toggle-booking-channel">
        <div class="col-md-12">
          <span (click)="toggleDisplayBookingChannel()">{{isShowBookingChannel ? "Hide" : "Show"}} OTA fields </span>
        </div>
      </div>
      <!--  If reservation is external, disable changes -->
      <div [hidden]="!isShowBookingChannel">
        <div class="row no-gutters">
          <div class="col-md-12">
            <b>Channel</b>
          </div>
          <div class="col-md-12">
            <dropdown-menu
              [menuList]="otaChannelList"
              [selectedItem]="selectedOtaChannel"
              [disableMenu]="isBookingChannel"
              [fullWidth]="true"
              (selectItem)="selectOtaChannel($event)"
            ></dropdown-menu>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12">
            <b>OTA Reservation #</b>
          </div>
          <div class="col-md-12">
            <input class="custom-input form-control" [ngClass]="{'invalid': bookingChannelReservationId.invalid}" formControlName="bookingChannelReservationId"
              type="text">
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12">
            <b>Virtual Email</b>
          </div>
          <div class="col-md-12">
            <input class="custom-input" formControlName="emailVirtual" type="text">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <button type="submit" class="btn btn-green" [disabled]="!customerEditForm.valid || isCustomerSave"
      (click)="saveCustomerEdit()">Save</button>
  </div>
</ng-template>

<!--Select payment modal-->
<ng-template #paymentTypeModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Payment type</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body checkbox-margin">
    <div class="normal-checkbox radio-style" *ngIf="hotelConfig.MUIfeature.manualPayment.includes('basic')">
      <input type="radio" name="type-selection" [checked]="isAddingManualPayment" (click)="changePaymentType('manualPayment')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Manual payment</div>
    </div>
    <div class="normal-checkbox radio-style" *ngIf="hotelConfig.MUIfeature.manualPayment.includes('integrated')">
      <input type="radio" name="type-selection" [checked]="isAddingInvoiceOmena" (click)="changePaymentType('invoiceForOmena')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Invoice - integrated</div>
    </div>
    <div class="normal-checkbox radio-style" *ngIf="hotelConfig.MUIfeature.manualPayment.includes('manual')">
      <input type="radio" name="type-selection" [checked]="isAddingInvoiceSalo " (click)="changePaymentType('invoiceForSalo')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Invoice - manual</div>
    </div>
    <div class="normal-checkbox radio-style">
      <input type="radio" name="type-selection" [checked]="isAddingPaymentLink" (click)="changePaymentType('paymentLink')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Payment link</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-green" (click)="openSelectedType()">Create</button>
  </div>
</ng-template>

<!--Invoice modal-->
<ng-template #invoiceModal>
  <div class="modal-header filled">
    <div class="modal-title pull-left">Integrated Invoice</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <div class="notice-container" *ngIf="currentInvoiceId">
      <div class="notice-icon">
        <span class="glyphicon glyphicon-info-sign"></span>
      </div>
      <div class="notice-content">Editing of invoices and credit invoice creation can only be done in Fennoa.</div>
    </div>
    <form [formGroup]="invoiceForm">
      <div class="row">
        <!-- Column 1 -->
        <div class="col-xs-12 col-sm-6">
          <div class="body-title">Invoice recipient</div>
          <label>Customer</label>
          <div class="search-container" *ngIf="invoiceVar">
            <input type="text" class="custom-input form-control" (click)="showResult()" (blur)="hideResult()" [(ngModel)]="invoiceVar.searchKeyword" (ngModelChange)="searchCustomer()" [ngModelOptions]="{standalone: true}" [disabled]="currentInvoiceId">
            <div class="search-icon">
              <span class="glyphicon glyphicon-search"></span>
            </div>
            <div class="search-result" *ngIf="invoiceVar.showSearch">
              <div class="result-error" *ngIf="invoiceVar.filterResult && invoiceVar.filterResult.length == 0">No existing customers match with your keyword</div>
              <ng-container *ngFor="let customer of invoiceVar.filterResult; let customerIndex = index">
                <div class="result-item" (click)="selectFennoaCustomer(customer)" [ngClass]="{active: (customer.Customer.customer_no + '-' + customer.Customer.name) == invoiceVar.searchKeyword}">{{customer.Customer.customer_no}} - {{customer.Customer.name}}</div>
              </ng-container>
            </div>
          </div>
          <div class="normal-checkbox right-align" [ngClass]="{'disabled':currentInvoiceId}">
            <input type="checkbox" [checked]="invoiceVar && invoiceVar.copyReservation" (click)="copyReservation()" [disabled]="currentInvoiceId">
            <div class="checkbox-label">Copy info from reservation</div>
            <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
          </div>

          <label>Name</label>
          <input type="text" class="custom-input form-control" (input)="inputCheckExisting()" formControlName="name" [ngClass]="{'invalid': !invoiceValidate.name}" (click)="removeInvoiceInvalid()">
          <div class="warning-text" *ngIf="invoiceVar && invoiceVar.existCustomerAlert">Customer already exists, if you do not select existing customers, this customer will be created.</div>

          <label>Street address</label>
          <input type="text" class="custom-input form-control" formControlName="address" [ngClass]="{'invalid': !invoiceValidate.address}" (click)="removeInvoiceInvalid()">
          <div class="row">
            <div class="col-md-6">
              <label>Postal code</label>
              <input type="text" class="custom-input form-control" formControlName="postalcode" [ngClass]="{'invalid': !invoiceValidate.postal}" (click)="removeInvoiceInvalid()">
            </div>
            <div class="col-md-6">
              <label>Post office</label>
              <input type="text" class="custom-input form-control" formControlName="city" [ngClass]="{'invalid': !invoiceValidate.city}" (click)="removeInvoiceInvalid()">
            </div>
          </div>

          <div class="row">
            <!-- Country -->
            <div class="col-md-6">
              <label>Country</label>
              <dropdown-menu
                [menuId]="'integrated-invoice-country'"
                [menuList]="countryList"
                [disableMenu]="currentInvoiceId"
                [selectedItem]="selectedInvoiceCountry"
                [fullWidth]="true"
                [searchable]="true"
                (selectItem)="selectInvoiceCountry($event)"
              ></dropdown-menu>
            </div>
            <!-- Language -->
            <div class="col-md-6">
              <label>Language</label>
              <dropdown-menu
                [menuId]="'integrated-invoice-language'"
                [menuList]="langList"
                [disableMenu]="currentInvoiceId"
                [selectedItem]="selectedInvoiceLang"
                [fullWidth]="true"
                (selectItem)="selectInvoiceLang($event)"
              ></dropdown-menu>
            </div>
          </div>

          <!-- Delivery method -->
          <label>Delivery method</label>
          <dropdown-menu
            [menuId]="'integrated-invoice-delivery-method'"
            [menuList]="invoiceDeliveryMethodList"
            [disableMenu]="currentInvoiceId"
            [selectedItem]="selectedInvoiceDeliveryMethod"
            [fullWidth]="true"
            (selectItem)="selectInvoiceDeliveryMethod($event)"
          ></dropdown-menu>

          <div class="e-invoice-address-for-email" *ngIf="selectedInvoiceDeliveryMethod && selectedInvoiceDeliveryMethod.value === 'email'">
            <label>Email</label>
          </div>
          <div class="e-invoice-address" *ngIf="selectedInvoiceDeliveryMethod && selectedInvoiceDeliveryMethod.value !== 'email'">
            <label>E-invoice address</label>
          </div>
          <input type="text" class="custom-input form-control" formControlName="einvoice_address" [ngClass]="{'invalid': !invoiceValidate.eAddress}" (click)="removeInvoiceInvalid()">

          <div class="e-invoice-operator" *ngIf="selectedInvoiceDeliveryMethod && selectedInvoiceDeliveryMethod.value !== 'email'">
            <label>E-invoice operator</label>
            <div class="search-container dropdown">
              <div class="delivery-input">
                <div class="wrap-field-dropdown">
                  <input type="text" class="custom-input form-control" (click)="showResultEInvoice()" (blur)="hideResultEInvoice()" [(ngModel)]="eInvoiceOperaterVar.searchKeyword" (ngModelChange)="searchEInvoice()" [ngModelOptions]="{standalone: true}" [disabled]="disableInvoiceForm" [ngClass]="{'invalid': !invoiceValidate.eOperator}">
                  <span><img class="drop-icon" src="assets/images/triangle-down.svg"></span>
                </div>
                
              </div>
              <div class="search-result" *ngIf="eInvoiceOperaterVar.showSearch">
                <div class="result-error" *ngIf="eInvoiceOperaterVar.filterResult.length == 0">No existing e-invoice operator match with your keyword</div>
                <ng-container *ngFor="let eInvoice of eInvoiceOperaterVar.filterResult; let index = index">
                  <div class="result-item" (click)="selectEInvoice(index)" [ngClass]="{active: (eInvoice.name + ' (' + eInvoice.swiftCode + ')') == eInvoiceOperaterVar.searchKeyword}">{{ eInvoice.name }} ({{ eInvoice.swiftCode }})</div>
                </ng-container>
              </div>
            </div>
          </div>
      
        </div>

        <!-- Column 2 -->
        <div class="col-xs-12 col-sm-6">
          <div class="invoice-container" *ngIf="currentInvoiceId">
            <div class="invoice-info">
              <div class="info-text" *ngIf="currentInvoiceNumber">Invoice ID (Fennoa): {{currentInvoiceNumber}}</div>
              <div class="info-text" *ngIf="!currentInvoiceNumber">Draft invoice</div>
              <div class="info-text">Invoice status: {{invoiceStatus}}</div>
            </div>
          </div>
          <div class="body-title">Invoice details</div>

          <label>Reservation total</label>
          <div class="selector-container bottom-border">
            <div class="selector-item">
              <dropdown-menu
                [menuId]="'integrated-invoice-type'"
                [menuList]="invoiceTypeList"
                [disableMenu]="currentInvoiceId"
                [selectedItem]="selectedInvoiceType"
                [buttonFontSize]="'16px'"
                [fullWidth]="true"
                (selectItem)="selectInvoiceType($event)"
              ></dropdown-menu>
            </div>
            <div class="selector-item input-percentage-item">
              <div class="price-input">
                <input class="custom-input form-control" appEditPercentage type="text" [(ngModel)]="invoicePaymentPercentage" [ngModelOptions]="{standalone: true}" (ngModelChange)="invoicePaymentChange('percent')" [disabled]="disableInvoiceForm || (selectedInvoiceType !== undefined && ['FULL', 'CREDIT_INVOICE', 'NONE'].includes(selectedInvoiceType.value))">
                <div class="symbol">%</div>
              </div>
            </div>
            <div class="selector-item input-item">
              <div class="price-input">
                <input class="custom-input form-control" appEditPriceWithZero type="text" [(ngModel)]="invoicePaymentAmount" [ngModelOptions]="{standalone: true}" (ngModelChange)="invoicePaymentChange('amount')"
                [disabled]="disableInvoiceForm || (selectedInvoiceType !== undefined && ['FULL', 'CREDIT_INVOICE', 'NONE'].includes(selectedInvoiceType.value))">
                <div class="symbol">{{utils.getCurrency(currency)}}</div>
              </div>
            </div>
            <div class="action-button green" *ngIf="selectedInvoiceType && selectedInvoiceType.value !== 'CREDIT_INVOICE' && !showInvoiceItem && !disableInvoiceForm">
              <button (click)="toggleInvoiceItem()">
                <span class="glyphicon glyphicon-plus-sign"></span>
              </button>
            </div>
          </div>

          <ng-container *ngIf="selectedInvoiceType && selectedInvoiceType.value !== 'CREDIT_INVOICE' && showInvoiceItem">
            <label>Additional items</label>
            <!-- Selected items -->
            <ng-container *ngFor="let invoiceItem of invoiceItemList; let invoiceIndex = index">
              <div class="selector-container">
                <div class="selector-item">
                  <dropdown-menu
                    [menuId]="'integrated-invoice-additional'"
                    [menuList]="invoiceAdditionalList"
                    [disableMenu]="currentInvoiceId"
                    [selectedItem]="invoiceItem.selectedMenu"
                    [fullWidth]="true"
                    (selectItem)="selectInvoiceItem(invoiceIndex, $event)"
                  ></dropdown-menu>
                </div>
                <div class="selector-item input-item">
                  <div class="price-input">
                    <input class="custom-input form-control" appEditPriceWithZero type="text"[(ngModel)]="invoiceItem.value" [ngModelOptions]="{standalone: true}" [disabled]="disableInvoiceForm">
                    <div class="symbol">{{utils.getCurrency(currency)}}</div>
                  </div>
                </div>
                <div class="action-button" *ngIf="!disableInvoiceForm">
                  <button (click)="removeInvoiceItem(invoiceIndex)">
                    <span class="glyphicon glyphicon-remove"></span>
                  </button>
                </div>
              </div>
            </ng-container>
            <!-- Add item -->
            <div class="selector-container" *ngIf="!disableInvoiceForm">
              <div class="selector-item">
                <dropdown-menu
                  [menuId]="'integrated-invoice-add-additional'"
                  [menuList]="invoiceAdditionalList"
                  [disableMenu]="currentInvoiceId"
                  [selectedItem]="selectedInvoiceAdditional"
                  [fullWidth]="true"
                  (selectItem)="addInvoiceItem($event)"
                ></dropdown-menu>
              </div>
              <div class="action-button" *ngIf="invoiceItemList.length === 0">
                <button (click)="toggleInvoiceItem()">
                  <span class="glyphicon glyphicon-remove"></span>
                </button>
              </div>
            </div>
            
          </ng-container>

          
          <ng-container *ngIf="selectedInvoiceType && selectedInvoiceType.value === 'CREDIT_INVOICE'">
            <label>Invoice to credit</label>
            <div class="search-container dropdown">
              <div class="delivery-input">
                <div class="wrap-field-dropdown">
                  <input type="text" class="custom-input form-control" (click)="showResultCreditInvoice()" (blur)="hideResultCreditInvoice()" [(ngModel)]="creditInvoiceVar.searchKeyword" (ngModelChange)="searchCreditInvoice()" [ngModelOptions]="{standalone: true}" [disabled]="disableInvoiceForm" [ngClass]="{'invalid': !invoiceValidate.creditInvoice}">
                  <span><img class="drop-icon" src="assets/images/triangle-down.svg"></span>
                </div>
              </div>
              <div class="search-result" *ngIf="creditInvoiceVar.showSearch">
                <div class="result-error" *ngIf="creditInvoiceVar.filterResult.length == 0">No credit invoices match with your keyword</div>
                <ng-container *ngFor="let creditInvoice of creditInvoiceVar.filterResult; let methodIndex = index">
                  <div class="result-item" (click)="selectCreditInvoice(methodIndex)" [ngClass]="{active: creditInvoice.name == creditInvoiceVar.searchKeyword}">{{ creditInvoice.invoiceNo }} - {{ creditInvoice.amount }} {{utils.getCurrency(currency)}}</div>
                </ng-container>
              </div>
            </div>

            <table class="custom-table" style="margin-top:15px" *ngIf="invoiceData && creditInvoiceVar.selectedInvoice">
              <thead>
                <th><div>Products</div></th>
                <th style="width: 130px"><div>Quantity</div></th>
                <th style="width: 130px"><div>Price</div></th>
              </thead>
              <tbody>
                <tr *ngFor="let invoice of invoiceData.SalesInvoiceRow">
                  <td>
                    {{getFennoaCreditProductName(invoice.code)}}
                  </td>
                  <td style="text-align: right;">
                    {{invoice.quantity}}
                  </td>
                  <td style="text-align: right;">
                    {{getFennoaCreditPrice(invoice.price_with_vat)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>

          <div class="terms-of-payment">
            <label>Terms of payment</label>
            <dropdown-menu
              [menuId]="'integrated-invoice-terms-of-payment'"
              [menuList]="invoiceTermsOfPaymentList"
              [disableMenu]="currentInvoiceId"
              [selectedItem]="selectedInvoiceTermsOfPayment"
              [fullWidth]="true"
              (selectItem)="selectInvoiceTermsOfPayment($event)"
            ></dropdown-menu>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Invoice date</label>
              <input type="text" class="custom-input form-control" [ngClass]="{'invalid': !invoiceValidate.invoiceDate}" (click)="removeInvoiceInvalid()"
                formControlName="invoiceDate"
                bsDatepicker 
                [(bsValue)]="invoiceDate" 
                (blur)="validateDate()" 
                (bsValueChange)="dateChange('invoiceDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', adaptivePosition: true }">
            </div>
            <div class="col-md-6">
              <label>Due date</label>
              <input type="text" class="custom-input form-control" [ngClass]="{'invalid': !invoiceValidate.dueDate}" (click)="removeInvoiceInvalid()"
                formControlName="dueDate"
                bsDatepicker 
                [(bsValue)]="dueDate" 
                (blur)="validateDate()" 
                (bsValueChange)="dateChange('dueDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', minDate: invoiceDate, adaptivePosition: true }">
            </div>
          </div>
          <!-- Your reference -->
          <div class="your-reference">
            <label>Your reference</label>
            <input type="text" class="custom-input form-control" (input)="inputCheckExisting()" formControlName="your_reference">
          </div>
          <!-- <label>VAT handling</label>
          <input type="text" class="custom-input" formControlName="vat_number"> -->
          <!-- <div class="text-right"><label class="clickable">Show more fields</label></div> -->
          <label>Description (optional)</label>
          <textarea type="text" rows="3" class="form-control" style="resize: none" formControlName="description" [ngClass]="{'invalid': !invoiceValidate.description}" (click)="removeInvoiceInvalid()"></textarea>
        </div>
        <div class="col-xs-12">
          <div class="invoice-button" style="margin-top: 15px;">
            <button class="btn btn-none-outline-green" [disabled]="invoiceSaving" *ngIf="!currentInvoiceId" (click)="clearInvoiceForm()">Clear</button>
            <button class="btn btn-green" [disabled]="currentInvoiceId || invoiceSaving" (click)="saveInvoice()">Save</button>
            <button class="btn btn-green" [disabled]="!currentInvoiceId || invoiceApproved || invoiceApproving" (click)="approveSendInvoice()">Approve & Send</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--Credit Invoice modal-->
<ng-template #creditInvoiceModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Options</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body more-indent">
    <form>
      <div class="centered-flexbox refund-form" [ngClass]="{selected: creditInvoiceFormSelection == 'downloadPdf'}">
        <div class="flex-item item-checkbox">
          <div class="normal-checkbox radio-style">
            <input type="radio" name="refund" (click)="changeCreditInvoiceOption('downloadPdf')"
              [checked]="creditInvoiceFormSelection == 'downloadPdf'">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">Download pdf</div>
          </div>
        </div>
      </div>
      <div class="centered-flexbox refund-form" [ngClass]="{selected: creditInvoiceFormSelection == 'createCreditInvoice'}">
        <div class="flex-item item-checkbox">
          <div class="normal-checkbox radio-style">
            <input type="radio" name="refund" (click)="changeCreditInvoiceOption('createCreditInvoice')"
              [checked]="creditInvoiceFormSelection == 'createCreditInvoice'">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">Create credit invoice</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer footer-flex">
    <div class="footer-button">
      <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
      <button type="submit" class="btn btn-green" (click)="proceedForCreditInvoice()">Proceed</button>
    </div>
  </div>
</ng-template>

<!--Download Credit Invoice modal-->
<ng-template #downloadCreditInvoiceModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Multiple invoices - select one to print</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body more-indent {{ manualInvoices.length > 5 ? 'scrollable-credit-invoice' : '' }}">
    <form>
      <ng-container *ngFor="let manualInvoice of manualInvoices; let i = index">
        <div class="centered-flexbox credit-invoice-form" [ngClass]="{selected: downloadCreditInvoiceFormSelection == manualInvoice.invoiceNo}" 
        *ngIf="!isLoadingInvoiceManual">
          <div class="flex-item item-checkbox">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="refund" (click)="changedownloadCreditInvoiceOption(manualInvoice)"
                [checked]="downloadCreditInvoiceFormSelection == manualInvoice.invoiceNo">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Invoice {{manualInvoice.invoiceNo}} created on {{manualInvoice.created | date:'dd.MM.yyyy'}}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
    <div class="row" style="height: 10rem;">
      <div class="col-md-6">
        <label>Language</label>
        <dropdown-menu
          [menuId]="'manual-invoice-download-lang'"
          [menuList]="langList"
          [selectedItem]="selectedInvoiceLang"
          [fullWidth]="true"
          (selectItem)="selectInvoiceLang($event)"
        ></dropdown-menu>
      </div>
    </div>
    <!-- Loading Invoice manual icon -->
    <div class="center-item" *ngIf="isLoadingInvoiceManual">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
  </div>
  <div class="modal-footer footer-flex">
    <div class="footer-button">
      <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
      <button type="submit" class="btn btn-green" [disabled]="!downloadCreditInvoiceFormSelection || isLoadingInvoiceManual" (click)="downloadForCreditInvoice()">Download</button>
    </div>
  </div>
</ng-template>

<!--Create Credit Invoice modal-->
<ng-template #createCreditInvoiceModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Multiple invoices - select one to credit</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body more-indent {{ manualInvoices.length > 5 ? 'scrollable-credit-invoice' : ''}}">
    <form>
      <ng-container *ngFor="let manualInvoice of manualInvoices; let i = index">
        <div class="centered-flexbox credit-invoice-form" [ngClass]="{selected: downloadCreditInvoiceFormSelection == manualInvoice.invoiceNo}" 
        *ngIf="!isLoadingInvoiceManual">
          <div class="flex-item item-checkbox">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="refund" (click)="changedownloadCreditInvoiceOption(manualInvoice)"
                [checked]="downloadCreditInvoiceFormSelection == manualInvoice.invoiceNo">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Invoice {{manualInvoice.invoiceNo}} created on {{manualInvoice.created | date:'dd.MM.yyyy'}}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Loading Invoice manual icon -->
      <div class="center-item" *ngIf="isLoadingInvoiceManual">
        <ng-lottie
          [options]="options"
          width="200px"
          height="200px"
        ></ng-lottie>
      </div>
    </form>
    <div class="row">
      <div class="col-md-6">
        <label>Set credit invoice date</label>
        <div class="datepicker-input">
          <input type="text" placeholder="Invoice date" class="form-control" bsDatepicker [(bsValue)]="creditInvoiceDate"
            #startDateInput="bsDatepicker" (blur)="validateDate()"
            [dateCustomClasses]="datepickerCustom"
            [bsConfig]="{isAnimated: true, containerClass:'theme-red reservation-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', minDate: minCreditInvoiceDate }">
          <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
            <span class="glyphicon glyphicon-calendar"></span>
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <label>Language</label>
        <dropdown-menu
          [menuId]="'manual-invoice-create-lang'"
          [menuList]="langList"
          [selectedItem]="selectedInvoiceLang"
          [fullWidth]="true"
          (selectItem)="selectInvoiceLang($event)"
        ></dropdown-menu>
      </div>
    </div>
  </div>
  <div class="modal-footer footer-flex">
    <div class="footer-button">
      <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
      <button type="submit" class="btn btn-green" [disabled]="!downloadCreditInvoiceFormSelection || isLoadingInvoiceManual" (click)="createCreditInvoiceFunc()">Create Credit Invoice</button>
    </div>
  </div>
</ng-template>

<!--Invoice modal for Salo hotels -->
<ng-template #invoiceSaloModal>
  <div class="modal-header">
    <div class="modal-title pull-left"><h4><b>Manual invoice</b></h4></div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalForInvoiceManual()"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="reservationSearch">
      <div class="row">
        <ng-container *ngIf="isHiddenSearch">
          <div class="col-md-6">
            <label>Search for reservations</label>
            <input class="form-control custom-input" formControlName="searchQuery">
          </div>
          <div class="col-md-3">
            <label>Start date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Start date" class="form-control custom-input" bsDatepicker [(bsValue)]="startDate"
                #startDateInput="bsDatepicker" (blur)="validateDate()" (bsValueChange)="dateChangeForInvoice('startDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <div class="col-md-3">
            <label>End date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="End date" class="form-control custom-input" bsDatepicker [(bsValue)]="endDate"
                #endDateInput="bsDatepicker" (blur)="validateDate()" (bsValueChange)="dateChangeForInvoice('endDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <br/>
          <div class="col-md-6">
            <label>Filters</label>
            <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
              <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="optionalFilter">
                <div class="menu-selected">{{selectedFilterLabel}}</div>
                <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
              </div>
              <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu
                (click)="utils.preventClose($event)">
                <div class="content-item" (click)="clearAllFilter()">Clear all</div>
                <ng-container *ngFor="let filter of filters; let i = index">
                  <div class="normal-checkbox" (click)="changeFilter(i,$event)">
                    <input type="checkbox" [checked]="filter.checked">
                    <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                    <div class="checkbox-label">{{filter.label}}</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <ng-container>
              <div class="box-selector">
                <div class="custom-radio" *ngFor="let object of searchObjects; let i = index">
                  <input type="checkbox" name="reservation" [checked]="object.checked" (click)="changeObject(i,$event)">
                  <div class="radio-box">{{object.label}}</div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-1">
            <button type="button" [disabled]="isLoadingInvoiceManual || isMoreLoading" class="btn btn-green format-search-reservation"
            (click)="search('search')"><span class="glyphicon glyphicon-search"></span> Search</button>
          </div>
        </ng-container>

        <div class="{{ !isHiddenSearch ? 'col-md-12 show-search-invoice-manual' : 'col-md-2' }}">
          <div class="search-text-for-invoice" (click)="showSearchText()"><b>{{ !isHiddenSearch ? "Click to open" : "Hide" }} search</b></div>
        </div>
        <br/>
        <div class="col-md-12 mt-10">Selected Invoice</div>
        <div class="col-md-12 mt-10">
          <div>
            <span *ngFor="let result of reservationCodes; let i = index" class="selected-invoice">
              <span>{{result}}</span><span class="remove" (click)="removeInvoice(result)">x</span>
            </span>
          </div>
        </div>
        <div class="col-md-12 mt-20">
          <div class="table-scroll">
            <table class="custom-table" *ngIf="!isLoadingInvoiceManual && searchResult && searchResult.length > 0">
              <colgroup>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col style="min-width: 50%;">
                <col>
                <col>
              </colgroup>
              <thead>
                <th style="width: 5%"><div class="padding">
                  <div class="normal-checkbox custom-checkbox-in-header-table" (click)="checkAllReservationsForInvoiceManual(false)">
                    <input type="checkbox" [checked]="isCheckAllReservations">
                    <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                  </div>
                </div></th>
                <th style="width: 25%"><div class="padding">Reservation</div></th>
                <th style="width: 25%" [ngClass]="{sortActive: orderBy == 'CONTACT_NAME'}">
                  <div class="flex-container padding">
                    <div class="table-title">Booker Information</div>
                    <div class="table-sort" (click)="toggleSorting('CONTACT_NAME')">
                      <span class="glyphicon"
                        [ngClass]="{'glyphicon-sort-by-attributes-alt': orderBy == 'CONTACT_NAME' && orderDir == 'DESC', 'glyphicon-sort-by-attributes': orderBy != 'CONTACT_NAME' || orderDir == 'ASC'}"></span>
                    </div>
                  </div>
                </th>
                <th style="width: 10%" [ngClass]="{sortActive: orderBy == 'CHECK_OUT'}">
                  <div class="flex-container padding">
                    <div class="table-title">Check <br>out</div>
                    <div class="table-sort" (click)="toggleSorting('CHECK_OUT')">
                      <span class="glyphicon"
                        [ngClass]="{'glyphicon-sort-by-attributes-alt': orderBy == 'CHECK_OUT' && orderDir == 'DESC', 'glyphicon-sort-by-attributes': orderBy != 'CHECK_OUT' || orderDir == 'ASC'}"></span>
                    </div>
                  </div>
                </th>
                <th style="width: 20%"><div class="padding">Guests</div></th>
                <th style="width: 15%"><div class="padding">Total <br>(remain)</div></th>
              </thead>
              <tbody>
                <ng-container *ngFor="let result of searchResult; let i = index">
                  <tr [ngClass]="{graybg: result.reservationState != 'CONFIRMED'}">
                    <td>
                      <div class="normal-checkbox custom-checkbox-in-body" (click)="checkReservationForInvoiceManual(i)">
                        <input type="checkbox" [checked]="result.isChecked">
                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                      </div>
                    </td>
                    <td>
                      <b>{{result.reservationCode}}</b>
                      <br>{{getChannelLabel(result.bookingChannel)}}
                      <ng-container *ngIf="result.bookingChannelReservationId">
                        <br><br>External channel reservation number: <b>{{result.bookingChannelReservationId}}</b>
                      </ng-container>
                    </td>
                    <td>
                      {{result.customer.firstName+' '+result.customer.lastName}}
                      <br *ngIf="(result.customer.emailReal)">{{result.customer.emailReal}}
                      <br *ngIf="(result.customer.emailVirtual)">{{result.customer.emailVirtual}}
                      <br>{{result.customer.mobile}}
                      <br *ngIf="(result.customer.companyName)">{{result.customer.companyName}}
                    </td>
                    <td> {{utils.convertStringDate(result.checkOut)}}</td>
                    <td>
                      <div *ngFor="let room of result.guestsByRoomLabel | keyvalue">
                        <b>Room {{room.key}}</b>
                        <div *ngFor="let guest of convertToArray(room.value)">
                          <div *ngIf="(guest.firstName !== 'NA' || guest.lastName !== 'NA')">{{guest.firstName + ' ' +
                            guest.lastName }}</div>
                        </div><br>
                      </div>
                    </td>
                    <td>
                      {{ result.remainingAmount.toFixed(2) }} {{utils.getCurrency(currency)}}
                    </td>
                  </tr>
                </ng-container>
                <tr class="invoicing-surcharge-row">
                  <th>
                    <div class="normal-checkbox custom-checkbox-in-body" (click)="checkInvoicingSurcharge()">
                      <input type="checkbox" [checked]="includeSurcharge">
                      <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                    </div>
                  </th>
                  <th class="invoicing-surcharge-text">Invoicing surcharge</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th class="invoicing-surcharge-text">
                    <input type="text" (blur)="inputSurchargePrice($event)" value="{{ surchargePrice.toFixed(2) }}">{{utils.getCurrency(currency)}}</th>
                </tr>
              </tbody>
              <tfoot>
                <tr class="dark-bg">
                  <td></td>
                  <td class="format-for-total-invoice">Total to invoice</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="format-for-total-invoice">{{ sumTotalRemainingPrice.toFixed(2) }} {{utils.getCurrency(currency)}}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <!-- Loading Invoice manual icon -->
          <div class="center-item" *ngIf="isLoadingInvoiceManual">
            <ng-lottie
              [options]="options"
              width="200px"
              height="200px"
            ></ng-lottie>
          </div>
        </div>
      </div>
    </form>
    <div class="table-pagination text-center" *ngIf="cleanEvent.totalItem > cleanEvent.items">
      <span class="page-link nav-control" [ngClass]="{'hide':cleanEvent.current == 1}" (click)="singlePageChange('first')">First</span>
      <span class="page-link nav-control" [ngClass]="{'hide':cleanEvent.current == 1}" (click)="singlePageChange('prev')">Prev</span>
      <span class="page-link dot" *ngIf="cleanEvent.current !== 1 && cleanEvent.finalPage > cleanEvent.showNav">...</span>
      <span class="page-link" *ngFor="let page of cleanEvent.numberOfPage"
        [ngClass]="{'active':cleanEvent.current == page}" (click)="changePage(page)">{{ page }}</span>
      <span class="page-link dot" *ngIf="cleanEvent.current + (this.cleanEvent.showNav - 1) < cleanEvent.finalPage">...</span>
      <span class="page-link nav-control" [ngClass]="{'hide':cleanEvent.current == cleanEvent.finalPage}" (click)="singlePageChange('next')">Next</span>
      <span class="page-link nav-control" [ngClass]="{'hide':cleanEvent.current == cleanEvent.finalPage}" (click)="singlePageChange('last')">Last</span>
    </div>
    <form [formGroup]="invoiceSaloForm">
      <label>Invoice information</label>
      <div class="row">
        <div class="col-md-6">
          <label>Name</label>
          <input type="text" class="custom-input" (input)="inputCheckExisting()" [ngClass]="{'invalid': !invoiceManualValidate.name}" formControlName="name" (click)="removeInvoiceManualInvalid()">
          <div class="warning-text" *ngIf="invoiceSaloVar && invoiceSaloVar.existCustomerAlert">Customer already exists, if you do not select existing customers, this customer will be created.</div>

          <label>Street address</label>
          <input type="text" class="custom-input" formControlName="address" [ngClass]="{'invalid': !invoiceManualValidate.address}" (click)="removeInvoiceManualInvalid()">

          <div class="row">
            <div class="col-md-6">
              <label>Postal code</label>
              <input type="text" class="custom-input" formControlName="postalcode" [ngClass]="{'invalid': !invoiceManualValidate.postal}" (click)="removeInvoiceManualInvalid()">
            </div>
            <div class="col-md-6">
              <label>Post office</label>
              <input type="text" class="custom-input" formControlName="city" [ngClass]="{'invalid': !invoiceManualValidate.city}" (click)="removeInvoiceManualInvalid()">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="terms-of-payment dropdown">
            <label>Terms of payment</label>
            <dropdown-menu
              [menuId]="'manual-invoice-terms-of-payment'"
              [menuList]="invoiceTermsOfPaymentList"
              [selectedItem]="selectedInvoiceTermsOfPayment"
              [fullWidth]="true"
              (selectItem)="selectInvoiceTermsOfPayment($event)"
            ></dropdown-menu>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Invoice date</label>
              <input type="text" class="custom-input" [ngClass]="{'invalid': !invoiceManualValidate.invoiceDate}" (click)="removeInvoiceManualInvalid()"
                formControlName="invoiceDate"
                bsDatepicker 
                [(bsValue)]="invoiceDate" 
                (blur)="validateDate()" 
                (bsValueChange)="dateChange('invoiceDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', adaptivePosition: true }">
            </div>
            <div class="col-md-6">
              <label>Due date</label>
              <input type="text" class="custom-input" [ngClass]="{'invalid': !invoiceManualValidate.dueDate}" (click)="removeInvoiceManualInvalid()"
                formControlName="dueDate"
                bsDatepicker 
                [(bsValue)]="dueDate" 
                (blur)="validateDate()" 
                (bsValueChange)="dateChange('dueDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', minDate: invoiceDate, adaptivePosition: true }">
            </div>
          </div>
          <!-- Your reference -->
          <div class="your-reference">
            <label>Your reference</label>
            <input type="text" class="custom-input" (input)="inputCheckExisting()" formControlName="your_reference">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <!-- Country -->
            <div class="col-md-6">
              <label>Country</label>
              <dropdown-menu
                [menuId]="'manual-invoice-country'"
                [menuList]="countryList"
                [selectedItem]="selectedInvoiceCountry"
                [fullWidth]="true"
                [searchable]="true"
                (selectItem)="selectInvoiceCountry($event)"
              ></dropdown-menu>
            </div>
            <div class="col-md-6">
              <label>Language</label>
              <dropdown-menu
                [menuId]="'manual-invoice-language'"
                [menuList]="langList"
                [selectedItem]="selectedInvoiceLang"
                [fullWidth]="true"
                (selectItem)="selectInvoiceLang($event)"
              ></dropdown-menu>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <label>Organization</label>
              <input type="text" class="custom-input" formControlName="companyName">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="invoice-manual-button">
            <button class="btn btn-none-outline-green grow-flex format-invoice-manual-btn" [disabled]="invoiceSaving" (click)="clearInvoiceForm()">Clear</button>
            <button class="btn btn-green grow-flex" [disabled]="isLoadingCreatePdf" (click)="checkCreatePdfForInvoiceManual()">Create pdf</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--Payment modal-->
<ng-template #paymentModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Payment</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <div *ngIf="this.manualPaymentTypeList.length && hotelConfig.MUIfeature.manualPaymentPreset" class="payment-type-radio-wrapper">
      <ng-container *ngFor="let titleItem of manualPaymentTypeList">
        <div class="payment-type-radio-item">
          <input type="radio" [checked]="titleItem === this.selectedPaymentTypeRadio"
            (click)="handleRadioCheckboxManualPaymentType(titleItem)">
          <div class="checkbox-box">
            <div class="radio-icon"></div>
          </div>
          <div class="checkbox-label">{{titleItem}}</div>
          <button *ngIf="this.editPaymentTypeMode" class="btn btn-link delete-btn" (click)="this.handleDeletePaymentType(titleItem)">X</button>
        </div>
      </ng-container>
      <div class="payment-type-action">
        <div *ngIf="!this.addPaymentTypeMode" class="normal">
          <button *ngIf="!this.editPaymentTypeMode" class="btn btn-none-outline-green" (click)="this.editPaymentTypeMode = true">Edit</button>
          <button *ngIf="this.editPaymentTypeMode" class="btn btn-none-outline-green" (click)="this.editPaymentTypeMode = false">Cancel edit</button>
          <button [disabled]="this.editPaymentTypeMode" class="btn btn-green" (click)="this.handleAddPaymentTypeMode()"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span>Add payment type</button>
        </div>
        <div *ngIf="this.addPaymentTypeMode" class="add">
          <input id="paymentTypeInput" type="text" [(ngModel)]="this.inputAddPaymentType" class="form-control custom-input">
          <button class="btn btn-green" [disabled]="this.inputAddPaymentType.length === 0" (click)="this.handleAddPaymentType()">Add</button>
          <button class="btn btn-none-outline-green" (click)="this.handleCancelPaymentTypeMode()">Cancel</button>
        </div>
      </div>
    </div>
    <form [class.disable-class]="this.addPaymentTypeMode || this.editPaymentTypeMode" [formGroup]="addPaymentForm">
      <div class="row">
        <div class="col-md-6">
          <label>Date</label>
          <div class="datepicker-input">
            <input type="text" [ngClass]="{'invalid': paymentDate.invalid}" formControlName="paymentDate"
              placeholder="Select date" class="form-control" #payment="bsDatepicker" bsDatepicker
              [bsConfig]="{ isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY' }">
            <button type="button" (click)="payment.toggle()">
              <span class="glyphicon glyphicon-calendar"></span>
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <label>Amount</label>
          <input type="text" formControlName="amount" [ngClass]="{'invalid': paymentAmount.invalid}" class="form-control custom-input" style="text-align: right; padding-left: 35px;">
          <div class="currency-icon-input">{{utils.getCurrency(currency)}}</div>
        </div>
        <div class="col-md-12" style="margin: 0">
          <div class="text-right" style="margin-top: -10px;">{{getPaymentAmountRangeNote()}}</div>
        </div>
        <div class="col-md-12">
          <label>Payment provider</label>
          <input type="text" formControlName="paymentProvider" [ngClass]="{'invalid': paymentProvider.invalid}"
            class="form-control custom-input">
        </div>
        <div class="col-md-12">
          <label>Payment reference</label>
          <input type="text" formControlName="paymentReference" [ngClass]="{'invalid': paymentReference.invalid}"
            class="form-control custom-input">
        </div>
        <div style="display: none;">
          <input type="text" formControlName="paymentType">
        </div>
        <!-- https://projectnelson.atlassian.net/browse/MUI-67 -->
        <!-- comment #49 -->
        <div class="col-md-12" *ngIf="isExternalWithExtra()">
          <label>Payment for</label>
          <div class="normal-checkbox radio-style">
            <input type="radio" name="type-selection" (click)="paymentForChange(false)" [checked]="!isManualPaidForOtaExtras">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">Room payment</div>
          </div>
          <div class="normal-checkbox radio-style" *ngIf="isExternalWithExtra()">
            <input type="radio" name="type-selection" (click)="paymentForChange(true)" [checked]="isManualPaidForOtaExtras">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">Extra payment</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div [class.disable-class]="this.addPaymentTypeMode || this.editPaymentTypeMode" class="modal-footer">
    <button type="submit" class="btn btn-green" (click)="modalRef.hide()">Cancel</button>
    <button type="submit" class="btn btn-green" [disabled]="addPaymentForm.invalid || disabledBtnWhileCallingAPI" (click)="addPayment()">Save</button>
  </div>
</ng-template>

<!--Message Type modal-->
<ng-template #messageTypeModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Message Type</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body checkbox-margin message-type-body">
    <div class="normal-checkbox radio-style message-type-checkbox" *ngIf="!isExternalReservation()">
      <input type="radio" name="type-selection" [checked]="messageType == 'CONFIRMATION'" (click)="changeMessageType('CONFIRMATION')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Confirmation</div>
    </div>
    <div class="normal-checkbox radio-style message-type-checkbox" *ngIf="isExternalReservation()">
      <input type="radio" name="type-selection" [checked]="messageType == 'CONFIRMATION_ROOM'" (click)="changeMessageType('CONFIRMATION_ROOM')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Confirmation - Room(s)</div>
    </div>
    <div class="normal-checkbox radio-style message-type-checkbox" *ngIf="isExternalReservation() && isExternalWithExtra()">
      <input type="radio" name="type-selection" [checked]="messageType == 'CONFIRMATION_EXTRA'" (click)="changeMessageType('CONFIRMATION_EXTRA')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Confirmation - Extra(s)</div>
    </div>
    <div class="normal-checkbox radio-style message-type-checkbox" *ngIf="hotelConfig.feature.showDoorInfo">
      <input type="radio" name="type-selection" [checked]="messageType == 'DOOR_CODE'" (click)="changeMessageType('DOOR_CODE')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Door code</div>
    </div>
    <div class="normal-checkbox radio-style message-type-checkbox">
      <input type="radio" name="type-selection" [checked]="messageType == 'REMINDER'" (click)="changeMessageType('REMINDER')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Reminder</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Close</button>
    <button type="submit" class="btn btn-green" 
    (click)="openNotificationModal(messageType == 'CONFIRMATION_EXTRA' ? true : false)">Continue</button>
  </div>
</ng-template>

<!--Notification modal-->
<ng-template #notificationModal>
  <div class="modal-header">
    <!-- Extra -->
    <div class="modal-title pull-left" *ngIf="messageType !== 'DOOR_CODE' && messageType !== 'CONFIRMATION' && messageType !== 'REMINDER'">Notification</div>
    <!-- Confirmation -->
    <div class="modal-title pull-left" *ngIf="messageType === 'CONFIRMATION'">Confirmation message</div>
    <!-- Door code -->
    <div class="modal-title pull-left" *ngIf="messageType === 'DOOR_CODE'">Door code message</div>
    <!-- Reminder -->
    <div class="modal-title pull-left" *ngIf="messageType === 'REMINDER'">Reminder message</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="notificationForm">
      <div class="centered-flexbox notification-form" *ngIf="hotelConfig.MUIfeature.sendNotificationMethods.includes('email')">
        <div class="flex-item item-checkbox">
          <div class="normal-checkbox">
            <input type="checkbox" [checked]="notification.email" (click)="notificationMethodChange('email', $event)">
            <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
            <div class="checkbox-label">Email</div>
          </div>
        </div>
        <div class="flex-item">
          <input type="text" [ngClass]="{'invalid':notificationEmail.invalid}" class="form-control custom-input"
            formControlName="email">
        </div>
      </div>
      <div class="centered-flexbox notification-form" *ngIf="!sendExtra && hotelConfig.MUIfeature.sendNotificationMethods.includes('sms')">
        <div class="flex-item item-checkbox">
          <div class="normal-checkbox">
            <input type="checkbox" [checked]="notification.sms" (click)="notificationMethodChange('sms', $event)">
            <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
            <div class="checkbox-label">SMS</div>
          </div>
        </div>
        <div class="flex-item">
          <input type="text" [ngClass]="{'invalid':notificationSMS.invalid}" class="form-control custom-input"
            formControlName="mobile">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <button type="submit" class="btn btn-green"
      [disabled]="(!notification.sms && !notification.email)"
      (click)="sendConfirmation()">Send</button>
  </div>
</ng-template>

<!--Refund modal-->
<ng-template #refundModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Refund Options</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body more-indent refund-modal">
    <div class="refund-padding" style="margin-bottom: 10px"><b>Refund for unsatisfied service</b></div>
    <form [formGroup]="refundForm">
      <div class="text-right refund-padding"><b>Remain</b></div>
      <div class="centered-flexbox refund-form" [ngClass]="{selected: refundFormSelection == 'moneyAmount'}">
        <div class="flex-item item-checkbox">
          <div class="normal-checkbox radio-style">
            <input type="radio" name="refund" (click)="changeRefundOption('moneyAmount')"
              [checked]="refundFormSelection == 'moneyAmount'">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">By money amount</div>
          </div>
        </div>
        <div class="flex-price">
          <div class="flex-item">
            <input type="text" class="form-control custom-input" (input)="calculateRemain('money', $event)"
              [ngClass]="{'invalid': moneyAmount.invalid}" formControlName="moneyAmount"
              style="text-align: right; padding-left: 35px;">
            <div class="currency-icon-input">{{utils.getCurrency(currency)}}</div>
          </div>
          <div class="flex-item item-remain">
            <div class="text-right" [ngClass]="{disabled: refundFormSelection != 'moneyAmount'}">{{refundRemain.money | currencyFormat: 'fullPriceLeft'}}</div>
          </div>
        </div>
      </div>
      <div class="centered-flexbox refund-form" [ngClass]="{selected: refundFormSelection == 'percentageAmount'}">
        <div class="flex-item item-checkbox">
          <div class="normal-checkbox radio-style">
            <input type="radio" name="refund" (click)="changeRefundOption('percentageAmount')"
              [checked]="refundFormSelection == 'percentageAmount'">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">By percentage</div>
          </div>
        </div>
        <div class="flex-price">
          <div class="flex-item">
            <input type="text" class="form-control custom-input" (input)="calculateRemain('percentage', $event)"
              [ngClass]="{'invalid': percentageAmount.invalid}" formControlName="percentageAmount"
              style="text-align: right; padding-right: 35px;">
            <div class="percentage-icon-input">%</div>
          </div>
          <div class="flex-item item-remain">
            <div class="text-right" [ngClass]="{disabled: refundFormSelection != 'percentageAmount'}">
              {{refundRemain.percentage | currencyFormat: 'fullPriceLeft'}}</div>
          </div>
        </div>
      </div>
      <div class="centered-flexbox refund-form {{ !hotelConfig.feature.giftCard ? 'hidden' : '' }}" [ngClass]="{selected: refundFormSelection == 'giftcardAmount'}">
        <div class="flex-item item-checkbox">
          <div class="normal-checkbox radio-style">
            <input type="radio" name="refund" (click)="changeRefundOption('giftcardAmount')"
              [checked]="refundFormSelection == 'giftcardAmount'">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">By giftcard</div>
          </div>
        </div>
        <div class="flex-price">
          <div class="flex-item">
            <input type="text" class="form-control custom-input" (input)="calculateRemain('giftcard', $event)"
              [ngClass]="{'invalid': giftcardAmount.invalid}" formControlName="giftcardAmount"
              style="text-align: right; padding-left: 35px;">
            <div class="currency-icon-input">{{utils.getCurrency(currency)}}</div>
            <label>Valid through</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Select date" class="form-control"
                [ngClass]="{'invalid': giftcardExpireDate.invalid}" formControlName="giftcardExpireDate"
                #giftcardRefund="bsDatepicker" bsDatepicker
                [bsConfig]="{ isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', adaptivePosition: true, minDate: refundGiftcardDate.minDate }">
              <button type="button" (click)="giftcardRefund.toggle()"
                [disabled]="refundFormSelection != 'giftcardAmount'">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <div class="flex-item item-remain">
            <div class="text-right" [ngClass]="{disabled: refundFormSelection != 'giftcardAmount'}">
              {{refundRemain.giftcard | currencyFormat: 'fullPriceLeft'}}</div>
          </div>
        </div>
      </div>
      <div class="refund-padding">
        <label>Reason</label>
        <input type="text" [ngClass]="{'invalid': reason.invalid}" formControlName="reason"
          class="form-control custom-input">
      </div>
    </form>
  </div>
  <div class="modal-footer footer-flex">
    <div class="footer-error" *ngIf="hasPendingRefund">Please resolve pending refunds in Payments section first!</div>
    <div class="footer-button">
      <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
      <button type="submit" class="btn btn-green" (click)="startRefund()"
        [disabled]="refundForm.invalid || hasPendingRefund || disabledBtnWhileCallingAPI">Refund</button>

    </div>
  </div>
</ng-template>

<!--Result modal-->
<ng-template #resultModal>
  <div class="modal-body text-center">
    <div class="error-icon red" *ngIf="resultElements.result == 'error'"><span
        class="glyphicon glyphicon-remove-sign"></span></div>
    <div class="error-icon green" *ngIf="resultElements.result == 'success'"><span
        class="glyphicon glyphicon-ok-sign"></span></div>
    <div class="error-title">{{resultElements.type}} {{resultElements.result}}</div>
    <div class="error-desc">{{resultElements.description}}</div>

    <button type="button" class="btn btn-green" [ngClass]="{'green':resultElements.result == 'success'}"
      (click)="modalRef.hide()">OK</button>
  </div>
</ng-template>

<!-- Confirm blocked reservation -->
<ng-template #confirmBlockedModal>
  <div class="modal-body text-center">
    <div class="error-icon"><span class="glyphicon glyphicon-question-sign"></span></div>
    <div class="error-title">Confirm booking for a blocked customer</div>
    <div class="error-desc">You are confirming a booking with a blocked customer. Customer will be automatically
      unblocked. Do you want to continue?</div>

    <button type="button" class="btn btn-outline-green" (click)="modalRef.hide()">No</button>
    <button type="button" class="btn btn-green" (click)="startConfirmReservation(true)">Yes</button>
  </div>
</ng-template>

<!-- Unblock -->
<ng-template #unblockModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Unblock reservation</div>
    <div class="error-desc">All customer instances belong to the email "{{reservation.customer.emailReal}}" and phone
      number "{{reservation.customer.mobile}}" will be unblocked. Do you want to continue?</div>

    <button type="button" class="btn btn-outline-green" (click)="modalRef.hide()">No</button>
    <button type="button" class="btn btn-green" (click)="unblockReservation()">Yes</button>
  </div>
</ng-template>

<!-- Cancel reservation -->
<ng-template #cancelReservationModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Cancel reservation</div>
    <ng-container *ngIf="!confirmedCancel">
      <div class="error-desc">Are you sure you want to cancel this reservation</div>

      <button type="button" class="btn btn-none-outline-green" [disabled]="isLoadingCancel" (click)="modalRef.hide()">No</button>
      <button type="button" class="btn btn-green" [disabled]="isLoadingCancel" (click)="confirmCancel()">Yes</button>
    </ng-container>
    <ng-container *ngIf="confirmedCancel">
      <div class="error-desc">Are you sure the room is clean?</div>
      <button type="button" [disabled]="isLoadingCancel" class="btn btn-none-outline-green" (click)="cancelReservation(false)">Don't know</button>
      <button type="button" [disabled]="isLoadingCancel" class="btn btn-green" (click)="cancelReservation(true)">Yes</button>
    </ng-container>
  </div>
</ng-template>

<!-- Cancel lineItems -->
<ng-template #cancelLineItemModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Items selected for cancellation</div>
    <ng-container *ngIf="!confirmedCancel">
      <div class="error-desc">Do you want to refund those items?</div>

      <button type="button" class="btn btn-none-outline-green" (click)="confirmCancelLineItem(false)">No</button>
      <button type="button" class="btn btn-green" (click)="confirmCancelLineItem(true)">Yes</button>
    </ng-container>
  </div>
</ng-template>
<!-- Confirm blocked reservation -->
<ng-template #confirmSSNModal>
  <div class="modal-body text-center">
    <div class="error-icon"><span class="glyphicon glyphicon-question-sign"></span></div>
    <div class="error-title">This is not a valid Finnish SSN</div>
    <div class="error-desc">Do you want to save?</div>

    <button type="button" class="btn btn-none-outline-green" (click)="cancellingSavingData()">No</button>
    <button type="button" class="btn btn-green" (click)="saveInfor()" [disabled]="isCustomerSave">Yes</button>
  </div>
</ng-template>
<!-- Blocked customer when edit -->
<ng-template #saveBlockedModal>
  <div class="modal-body text-center">
    <div class="error-icon"><span class="glyphicon glyphicon-exclamation-sign"></span></div>
    <div class="error-title">Blocked</div>
    <div class="error-desc">Customer information you are saving is blocked!</div>

    <button type="button" class="btn btn-green" (click)="openCustomerEditModal()">Close</button>
  </div>
</ng-template>


<!-- Check Create Pdf modal -->
<ng-template #checkCreatePdfModal>
  <div class="modal-body text-center">
    <div class="error-icon"><span class="glyphicon glyphicon-question-sign"></span></div>
    <div class="error-desc">One of the reservations has already been invoiced. Creating a new invoice will automatically generate a credit invoice</div>

    <button type="button" class="btn btn-outline-green" (click)="modalRef.hide()">No</button>
    <button type="button" class="btn btn-green" (click)="createPdfForInvoiceManual()">Yes</button>
  </div>
</ng-template>


<!--Create payment link modal-->
<ng-template #paymentLinkModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Create payment link</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="hotelConfig.MUIfeature.partialPaymentLink">
      Select amount to be paid for payment link
      <div class="payment-link-radio-inline">
        <div class="radio-item" *ngFor="let option of paymentLinkOptions">
          <div class="normal-checkbox radio-style">
            <input type="radio" name="type-selection" [checked]="selectedPaymentLinkOption.value === option.value" (click)="setPaymentLinkOption(option)">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">{{option.label}}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- Balance (Full amount) -->
    <div *ngIf="selectedPaymentLinkOption.value === 'full'" style="padding: 15px 0">
      <div>Customer will use this payment link to pay the remaining balance of the reservation.</div>
      <div class="input-flex">
        <div class="flex-input">
          <label>Payment link</label>
          <input type="text" (click)="selectAllPaymentLink($event)" class="custom-input form-control" [value]="paymentLink" readonly>
        </div>
        <div class="flex-button">
          <button type="submit" class="btn btn-green" (click)="copyPaymentLink(true, paymentLink)">Copy</button>
        </div>
      </div>
    </div>
    <!-- Partial amount -->
    <div *ngIf="selectedPaymentLinkOption.value === 'partial'" style="padding: 15px 0">
      <div class="row">
        <div class="col-xs-12 col-sm-5">
          <form [formGroup]="partialPaymentForm">
            <div class="input-flex">
              <div class="flex-input">
                <label>Amount</label>
                <input type="text" formControlName="amount" [ngClass]="{'invalid': partialAmount.invalid}" [disabled]="generatingLink" class="form-control custom-input" style="text-align: right; padding-left: 35px;">
                <div class="currency-icon-input">{{utils.getCurrency(currency)}}</div>
              </div>
              <div class="flex-button">
                <button type="submit" class="btn btn-green" (click)="generatePaymentLink('partial')" [disabled]="generatingLink">Generate link</button>
              </div>
            </div>
            <div style="margin-top: 10px;">
              Maximum amount: <b>{{totalBalance | currencyFormat:'fullPrice'}}</b>
            </div>
          </form>
        </div>
        <div class="col-xs-12 col-sm-7">
          <div class="input-button-with-container">
            <div class="input-flex">
              <div class="flex-input">
                <label>Generated payment link</label>
                <input type="text" (click)="selectAllPaymentLink($event)" class="custom-input form-control" [disabled]="!paymentLink" [value]="paymentLink" readonly>
              </div>
              <div class="flex-button">
                <button type="submit" class="btn btn-green" [disabled]="!paymentLink" (click)="copyPaymentLink(true, paymentLink)">Copy</button>
              </div>
              <div class="loading-container loading" [ngClass]="{'loading': generatingLink || !paymentLink}">
                <span *ngIf="!generatingLink && !paymentLink">Input amount and click Generate link</span>
                <span *ngIf="generatingLink">Generating link...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payment-history-container" *ngIf="paymentLinkHistory.length">
        <h4>Payment link history</h4>
        <input type="text" class="custom-input form-control" placeholder="Search payment link ID" style="margin: 15px 0; max-width: 400px;" [(ngModel)]="paymentIdSearchValue" (ngModelChange)="filterPaymentHistory()">
        <div style="text-align: center; margin-top: 25px" *ngIf="!filteredPaymentLinkHistory.length">
          <h4>No payment link ID matches with the query</h4>
        </div>
        <div class="custom-table-wrapper" *ngIf="filteredPaymentLinkHistory.length">
          <table class="custom-table">
            <thead>
              <tr>
                <th width="190px">ID</th>
                <th width="110px" class="text-right">Amount</th>
                <th width="100px">Created</th>
                <th width="100px">Expire</th>
                <th>Status</th>
                <th width="120px">Payment link</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let linkItem of filteredPaymentLinkHistory">
                <td [style]="{color: !linkItem.linkAvailable ? 'red' : '#424F68'}">{{linkItem.uuid}}</td>
                <td style="text-align: right;">{{linkItem.amount}}</td>
                <td>{{linkItem.created}}</td>
                <td>{{linkItem.expire}}</td>
                <td><span [style]="{color: !linkItem.linkAvailable ? 'red' : '#424F68'}">{{linkItem.status}}</span></td>
                <td>
                  <div class="link-container" *ngIf="linkItem.linkAvailable" (click)="copySelectedLinkUuid(linkItem.uuid)">
                    <div class="link-url">Copy link</div>
                    <div class="link-icon">
                      <span class="glyphicon glyphicon-copy"></span>
                    </div>
                  </div>
                  <div *ngIf="!linkItem.linkAvailable" style="color: red">
                    Not available
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Additional service modal -->
<ng-template #additionalServicesModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Add additional service</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <div class="additional-service-container">
      <div class="modal-title">Select products to add to reservation</div>
      <div class="service-item-row">
        <div class="row-item-action">
          <button><span class="glyphicon glyphicon-remove"></span></button>
        </div>
        <div class="row-item-info">
          <div class="item-product">
            <label>Service name</label>
            <div class="btn-group mui-menu full-width" dropdown>
              <button dropdownToggle type="button" class="btn dropdown-toggle">
                Airport transfer <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li>Airport transfer</li>
                <li>Breakfast (Espresso house)</li>
                <li>Cleaning</li>
              </ul>
            </div>
          </div>
          <div class="item-date">
            <label>Date</label>
            <div class="datepicker-input big-icon">
              <input type="text" placeholder="Select date" class="form-control" bsDatepicker
                [minDate]="utils.newDate(reservation.startDate)"
                [maxDate]="utils.newDate(reservation.endDate)"
                [bsConfig]="{ isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', adaptivePosition: true }">
              <button type="button">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="row-item-price">
          <div class="item-quantity">
            <label>Quantity</label>
            <input type="text" class="form-control custom-input">
          </div>
          <div class="item-unit-price">
            <label>Unit price</label>
            <input type="text" class="form-control custom-input">
            <div class="currency-icon">{{utils.getCurrency(currency)}}</div>
          </div>
          <div class="item-vat">
            <label>VAT</label>
            <div class="btn-group mui-menu full-width" dropdown>
              <button dropdownToggle type="button" class="btn dropdown-toggle">
                24% <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li>10%</li>
                <li>14%</li>
                <li>24%</li>
              </ul>
            </div>
          </div>
          <div class="item-total-price">
            <label>Total price</label>
            <input type="text" class="form-control custom-input">
            <div class="currency-icon">{{utils.getCurrency(currency)}}</div>
          </div>
        </div>
        <div class="row-item-desc">
          <div class="item-desc">
            <label>Description</label>
            <textarea class="form-control custom-input"></textarea>
          </div>
        </div>
        <div class="row-item-checkbox">
          <div class="item-refundable">
            <div class="normal-checkbox">
              <input type="checkbox">
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">Refundable</div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-group mui-menu full-width add-menu" dropdown>
        <button dropdownToggle type="button" class="btn dropdown-toggle">
          Add new product <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li>Airport transfer</li>
          <li>Breakfast (Espresso house)</li>
          <li>Cleaning</li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>